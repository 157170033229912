//오더 페이지 왼쪽 오더 리스트 헤더
export default [
  {
    text: "NO.",
    value: "order",
    width: "70px",
    align: "center",
    class: "printFormDialogRightTableHeader",
  },
  {
    text: "COLOR",
    value: "item",
    width: "100px",
    align: "center",
    class: "printFormDialogRightTableHeader",
  },
  {
    text: "수 량",
    value: "placefoward",
    width: "100px",
    align: "center",
    class: "printFormDialogRightTableHeader",
  },
  {
    text: "샘 플",
    value: "placefoward",
    width: "100px",
    align: "center",
    class: "printFormDialogRightTableHeader",
  },
];
