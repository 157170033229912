<template>
  <v-dialog
    v-model="openDialog"
    max-width="600px"
    persistent
    no-click-animation
    @keydown.esc="close()"
  >
    <v-card @keypress.enter="save()">
      <v-card-title>
        <span> {{ titleReleaseeSave }} </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                :value="release.place_forward ? release.place_forward.name : ''"
                disabled
                label="출고처"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                label="저장 날짜"
                :value="
                  release.date_register
                    ? this.dateFormat(release.date_register)
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                label="출고날짜"
                :value="
                  release.date_delivery
                    ? this.dateFormat(release.date_delivery)
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="$store.state.auth_grade <= 2">
              <v-text-field
                label="비고"
                v-model="$store.state.approvalMemo"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> 아니오 </v-btn>

        <v-btn
          v-if="!release.companionCheck"
          color="blue darken-1"
          text
          @click="save"
        >
          예
        </v-btn>
        <v-btn v-else color="blue darken-1" text @click="releaseSave">
          반려
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      memo: "",
      saveOrder: "",
    };
  },
  computed: {
    ...mapGetters({
      release: "getFabricRelease",
      order: "getProductionForm",
    }),
    titleReleaseeSave() {
      return !this.release.companionCheck
        ? this.release.kind === 17 && this.release.requestDoc.kind === 0
          ? this.release._id
            ? "재고 수정"
            : "재고 등록"
          : this.$store.state.auth_grade >= 2
          ? "출고의뢰서 승인"
          : "출고의뢰서 승인 요청"
        : "출고의뢰서 반려";
    },
    openDialog() {
      return (
        (this.$store.state.isFabricRelease ||
          this.$store.state.isFabricManufacture) &&
        this.$store.state.confirmDialog
      );
    },
  },
  methods: {
    close() {
      this.$store.commit("setProductionConfirm");
      this.memo = "";
    },

    async save() {
      await this.$store.dispatch("SELECT_ORDER", this.order);
      if (this.order.__v !== this.$store.state.selectOrder.__v) return;

      if (this.$store.state.isFabricRelease) this.setReleaseSaveData();
      if (this.$store.state.isFabricRelease && this.release._id)
        this.modifyReleaseData();

      this.$store.commit("setSaveData", this.saveOrder);
      await this.$store.dispatch("SAVE_ORDER");
      this.close();
    },

    setOrderPorgress(doc) {
      const progress = this.saveOrder.progress;
      if (!this.release._id) {
        progress.push(this.newProgress(doc));
      } else {
        const index = progress.findIndex((x) => x.id === doc.id);
        progress[index] = this.newProgress(doc);
      }
      return progress;
    },

    newProgress(doc) {
      const progress = {
        status: doc.status,
        date_register: doc.date_register,
        user: this.release.liaison.name,
        kind: doc.kind,
        memo: "",
        log: this.progressLogMake(),
        id: doc.id,
        history: [],
      };
      return progress;
    },

    progressLogMake() {
      let log = "";
      if (this.release.kind === 7 || this.release.kind === 18)
        log = "가공의뢰서 매입";

      if (this.release.kind === 19 || this.release.kind === 8)
        log = `${this.release.place_forward.name} -> ${
          this.release.place_receive
            ? this.release.place_receive.name
            : "미지정"
        }`;

      if (this.release.kind === 9 || this.release.kind === 15)
        log = `${this.release.place_forward.name} -> ${
          this.release.place_sales ? this.release.place_sales.name : "미지정"
        }`;

      return log;
    },

    setReleaseSaveData() {
      this.saveOrder = this.$store.state.selectOrder;
      const saveRelease = {
        ...this.releaseParse(),
        ...this.$store.state.saveReleaseColor,
      };

      // Object.assign(
      //   this.releaseParse(),
      //   this.$store.state.saveReleaseColor,
      // );

      this.saveOrder.release.push(saveRelease);
      this.saveOrder.progress = this.setOrderPorgress(saveRelease);
      this.$store.dispatch("SET_APPROVAL_SAVE_DATA", saveRelease);
    },

    modifyReleaseData() {
      const index = this.saveOrder.progress.findIndex(
        (x) => x.id === this.release.id,
      );
      const release = this.saveOrder.release.find(
        (x) => x._id === this.release._id,
      );
      release.id = `${release.id}_bak_${this.saveOrder.release.length}`;
      release.status = "99";

      this.saveOrder.progress[index].history.push({
        id: release.id,
        log: history.length,
        date: new Date(Date.now()),
      });
    },

    releaseParse() {
      const release = {
        company: this.$store.state.auth_company, // ref company.id
        id: this.release.id
          ? this.release.id
          : this.productionIdMaker("release"), // 고유 값
        order_kind: 1,
        liaison: this.release.liaison._id, // 담당자
        kind: this.release.kind, // 검사서 종류
        unit_sign: this.release.unit_sign, // 수량 단위
        date_register: this.release.date_register, // 작성 날짜
        date_modify: new Date(Date.now()), // 저장 날짜
        date_delivery: this.release.date_delivery,
        total_quantity: this.$store.state.colorTotal,
        order: this.order.order,
        place_receive: this.release.place_receive
          ? this.release.place_receive._id
          : "",
        place_receive_memo: this.release.place_receive_memo, //입고지 메모
        remark: this.release.remark,
        manufacture:
          this.release.requestDoc.kind === 7 ||
          this.release.requestDoc.kind === 18
            ? this.release.requestDoc.id
            : "", // 가공의뢰서
        precautions: this.release.precautions, // 주의사항
        item: this.release.item._id, // 아이템
        place_forward: this.release.place_forward._id, // 출고처
        status: this.discretionStatus(),
      };
      return release;
    },

    discretionStatus() {
      return 2 <= this.$store.state.auth_grade
        ? "40"
        : this.release._id
        ? "50"
        : "40";
    },

    productionIdMaker(kind) {
      return `${this.order.order}_${kind}_${this.numFromat(kind)}`;
    },
    numFromat(kind) {
      const num =
        kind === "release"
          ? this.order.release.length + 1
          : this.order.manufacture.length + 1;
      return 10 > num ? `00${num}` : 100 > num ? `0${num}` : `${num}`;
    },
  },
};
</script>
