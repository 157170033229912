import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menu_date_appearance: false,
    };
  },
  computed: {
    ...mapGetters({
      appearance: "getAppearanceForm",
      loginUser: "getLoginUser",
      appearanceList: "getAppearanceList",
    }),

    formAppearanceDisabled() {
      return false;
    },
  },
  methods: {
    totalQuantity(i) {
      const color = this.appearance.color_list[i];

      return color.name ? color.sucess_quantity + color.fail_quantity : 0;
    },

    //////////////////////////////////////// 외관검사서 이력 기능 /////////////////////////////////////
    inputSuccess(i, data) {
      const color = this.appearance.color_list[i];
      const commaColor = this.appearance.commaColor[i];

      color.sucess_quantity = this.parsingNum(data);

      if (color.sucess_quantity < 0) color.sucess_quantity = "";
      commaColor.sucess_quantity = this.usingComma(data);
    },

    inputFail(i, data) {
      const color = this.appearance.color_list[i];
      const commaColor = this.appearance.commaColor[i];

      color.fail_quantity = this.parsingNum(data);

      if (color.fail_quantity < 0) color.fail_quantity = "";
      commaColor.fail_quantity = this.usingComma(data);
    },

    //////////////////////////////////////// 외관검사서 이력 기능 끝 /////////////////////////////////////

    //////////////////////////////////////// 외관검사서 기타 기능 /////////////////////////////////////
    close() {
      this.$store.commit("setAppearanceForm");
    },
    //00. 공통 함수
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },
  },
  //////////////////////////////////////////////////////////////////////////////////////////////
};
