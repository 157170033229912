<template>
  <div
    class="pt-1 pl-5"
    style="
      display: grid;
      grid-template-columns: 5fr 5fr;
      grid-template-rows: 2rem;
    "
  >
    <div
      class="text-bold releaseGridBorderLeft"
      style="border-bottom: thin solid rgba(0, 0, 0, 1)"
    >
      문서 정보
    </div>
    <div
      class="text-bold releaseGridBorder"
      style="border-bottom: thin solid rgba(0, 0, 0, 1)"
    >
      <v-select
        :value="this.$store.state.selectDocData"
        class="releaseSelectFontSize"
        item-text="doc"
        :items="docDataList"
        return-object
        @change="changeDocData($event)"
      />
    </div>
    <div style="height: 0.5rem"></div>
    <div style="height: 0.5rem"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      docDataList: "getDoctDataList",
    }),
  },
  methods: {
    changeDocData(data) {
      this.$store.commit("setSelectDocData", data);
      this.$store.dispatch("PARSE_DOCDATA");
    },
  },
};
</script>
