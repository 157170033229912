<template>
  <v-dialog
    v-model="cancelDialog.dialog"
    max-width="500px"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ btnTitle }}
        </span>
      </v-card-title>

      <v-card-text>{{ text }} </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> 닫 기 </v-btn>
        <v-btn
          color="blue darken-1"
          text
          type="number"
          @click="docStatusUserGradeCheck()"
        >
          {{ btnTitle }}
        </v-btn>
        <v-btn
          v-if="!this.isUser && cancelDialog.status === '90'"
          color="blue darken-1"
          text
          type="number"
        >
          반려
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      cancelDialog: "getCancelDialog",
    }),

    btnTitle() {
      return this.cancelDialog.status === "20" ||
        this.cancelDialog.status === "30" ||
        this.cancelDialog.kind === "appearance"
        ? "삭 제"
        : this.cancelDialog.kind === "release"
        ? !this.isUser || this.$store.state.releaseForm.requestDoc.kind === 0
          ? "삭 제"
          : "삭제 요청"
        : !this.isUser
        ? "삭 제"
        : "삭제 요청";
    },

    isNewDocReq() {
      return (
        this.cancelDialog.status === "20" ||
        this.cancelDialog.status === "30" ||
        this.cancelDialog.kind === "appearance"
      );
    },

    isUser() {
      return this.$store.state.auth_grade < 2;
    },

    text() {
      return this.isNewDocReq
        ? "문서를 삭제 하면 복구 할수 없습니다. 그래도 삭제 하시겠습니까?"
        : !this.isUser
        ? "문서를 삭제 하면 복구 할수 없습니다. 그래도 문서를 삭제 하시겠습니까?"
        : "삭제 요청 하시겠습니까?";
    },
  },
  methods: {
    close() {
      this.$store.commit("setCancelDialog");
    },

    docDelete() {
      if (this.cancelDialog.kind === "release" && !this.isUser)
        this.$store.dispatch("FIND_CALCULATE_APPROVAL", this.cancelDialog.id);

      if (
        (this.cancelDialog.kind === "release" &&
          this.$store.state.releaseForm.kind === 17) ||
        this.$store.state.releaseForm.kind === 12
      )
        this.$store.dispatch(
          "STOCK_VERSION_CHECK",
          this.$store.state.releaseForm.id,
        );
      else if (
        this.cancelDialog.kind === "release" &&
        this.$store.state.releaseForm.requestDoc.kind === 17 &&
        !this.isUser
      )
        this.$store.dispatch("REQUEST_STOCK_VERSION_CHECK_CANCEL");
      else this.$store.dispatch("SAVE_PARSE_DATA", "production");
    },

    newDocDelete() {
      this.$store.commit("setAPILoading", true);
      if (this.cancelDialog.kind === "appearance")
        this.$store.dispatch("SAVE_PARSE_DATA", "production");
      else {
        const manufacture = this.$store.state.manufactureForm;
        const release = this.$store.state.releaseForm;
        const isRelease = this.cancelDialog.kind === "release";
        const param = {
          docType: this.cancelDialog.kind,
          id: this.cancelDialog.id,
          form: !isRelease ? manufacture : release,
          orderNo: this.$store.state.productionOrderForm.order,
          company: this.$store.state.auth_company,
          referenceDoc: !isRelease ? { kind: 0 } : release.requestDoc,
        };
        this.$store.dispatch("CALL_PRODUCTION_PAGE_NEW_REQ_DELETE", param);
      }
      this.$store.commit("setAPILoading", false);
    },

    // 문서 status 와 유저 grade 확인하여
    docStatusUserGradeCheck() {
      if (this.isNewDocReq) this.newDocDelete();
      else this.docDelete();
    },
  },
};
</script>
