//생산 페이지 원가 다이아로그 헤더
export default [
  {
    text: "가공량",
    value: "release_amount",
    align: "center",
    width: "100px",
  },
  {
    text: "가공료",
    value: "release_fee",
    align: "center",
    width: "100px",
  },
  {
    text: "생산량",
    value: "production_amount",
    align: "center",
    width: "100px",
  },
  {
    text: "LOSS",
    value: "loss",
    align: "center",
    width: "100px",
  },
  {
    text: "Result",
    value: "result",
    align: "center",
    width: "100px",
  },
  { value: "edit", width: "50px", sortable: false },
  { value: "actions", width: "50px", sortable: false },
];
