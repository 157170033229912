<template>
  <v-dialog
    :value="dialog_file_upload"
    @click:outside="close()"
    @keydown.esc="close()"
    max-width="600px"
  >
    <v-card>
      <div
        v-if="loading"
        style="
          position: absolute;
          background-color: rgba(255, 255, 255, 0.5);
          width: 100%;
          height: 100%;
        "
      >
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          class="loadingSpinner ov"
        />
      </div>
      <v-toolbar color="primary" dark dense>
        <v-card-title><span>첨부파일 </span></v-card-title>
      </v-toolbar>

      <v-card-text style="height: 200px" class="pt-3">
        <div style="display: flex">
          <div style="width: 90%">
            <v-file-input
              v-model="uploadFile"
              outlined
              hide-details
              dense
              label="최대 업로드용량은 10MB입니다."
              :rules="rules"
              show-size
            />
          </div>
          <div style="padding-left: 5px; width: 13%">
            <v-btn
              color="primary"
              @click="filUplod()"
              :disabled="fileValidate()"
              >등록</v-btn
            >
          </div>
        </div>
        <div
          style="
            height: 110px;
            border: thin solid;
            overflow-y: overlay;
            overflow-x: hidden;
            margin-top: 3px;
          "
        >
          <div v-for="(file, i) in attachmentList" :key="file.name">
            <div style="display: flex; align-items: center">
              <v-icon
                :disabled="loading"
                small
                @click="deleteFile(i)"
                class="pr-1"
              >
                mdi-delete
              </v-icon>
              <div style="width: 80%">
                <a @click="dbFileDown(file)">{{ file.name }}</a>
              </div>
              <div style="width: 15%">
                {{ formaBytes(file.size ? file.size : 0) }}
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- <v-btn color="primary" @click="save()" text> 저 장 </v-btn> -->
        <v-btn color="primary" @click="close()" text> 닫 기 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  postfile,
  downloadFile,
  deletefile,
  getfileByIdAndName,
} from "@/api/file";

export default {
  data() {
    return {
      uploadFile: { name: "" },
      loading: false,
      rules: [
        (value) =>
          !value || value.size < 10000000 || "업로드 최대용량은 10MB입니다.",
      ],
    };
  },

  computed: {
    ...mapGetters({
      attachmentList: "getAttachmentList",
      attachmentId: "getAttachmentId",
    }),

    dialog_file_upload() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "dialog_file_upload"
        ? true
        : false;
    },
  },

  methods: {
    fileValidate() {
      return this.uploadFile &&
        this.uploadFile.name &&
        this.uploadFile.size < 10000000
        ? false
        : true;
    },

    //등록 버튼 클릭
    async filUplod() {
      try {
        const result = await getfileByIdAndName(
          this.$store.state.auth_company,
          this.attachmentId,
          this.uploadFile.name,
        );

        if (result.status !== 200 || result.data)
          throw "중복된 첨부 파일 입니다.";

        const formData = new FormData();
        formData.append("id", this.attachmentId);
        formData.append("file", this.uploadFile);
        await this.attachmentSave(formData);
        this.uploadFile = null;
      } catch (e) {
        this.loading = !this.loading;
        console.log(e);
        this.$store.dispatch("GET_ATTACHMENTLIST", this.attachmentId);
        this.loading = !this.loading;
      }
    },

    async attachmentSave(formData) {
      try {
        this.loading = !this.loading;

        const result = await postfile(formData);

        if (result.status !== 200) throw `error: ${result.status}`;

        await this.$store.dispatch("GET_ATTACHMENTLIST", this.attachmentId);

        this.loading = !this.loading;
      } catch (e) {
        console.log(e);
      }
    },

    async deleteFile(i) {
      try {
        this.loading = !this.loading;
        const result = await deletefile(this.attachmentList[i]._id);

        if (result.status !== 200) throw `error: ${result.status}`;

        this.loading = !this.loading;
      } catch (e) {
        console.log(e);
        this.loading = !this.loading;
      }
      this.$store.dispatch("GET_ATTACHMENTLIST", this.attachmentId);
    },

    async dbFileDown(file) {
      try {
        const result = await downloadFile(
          this.$store.state.auth_company,
          file._id,
        );

        if (result.status !== 200) throw `error: ${result.status}`;

        const downFile = new Blob([result.data]);
        const downloadUrl = window.URL.createObjectURL(downFile);
        let element = document.createElement("a");
        element.href = downloadUrl;
        element.download = file.name;
        element.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (e) {
        console.log(e);
      }
    },

    // dialog off
    close() {
      this.$store.state.isDialog = false;
    },
  },
};
</script>

<style></style>
