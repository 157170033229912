import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      doc: "getFabricDoc",
      loginUser: "getLoginUser",
      colorList: "getProductionColor",
      colorTotal: "getProductionColorTot",
    }),
    docTitle() {
      return this.doc.kind === 7 || this.doc.kind === 18
        ? this.manufactureDocTittle
        : this.releaseDocTitle;
    },
    manufactureDocTittle() {
      return `${this.doc.kind === 18 ? "생지" : ""} 가공의뢰서`;
    },
    releaseDocTitle() {
      return `${
        this.doc.kind === 8
          ? "매입"
          : this.doc.kind === 9
          ? "매출"
          : this.doc.kind === 10
          ? "검사"
          : this.doc.kind === 12
          ? "선매입"
          : this.doc.kind === 15
          ? "매입/매출"
          : this.doc.kind === 19
          ? "생지"
          : ""
      } 출고의뢰서`;
    },

    isNewDocBtn() {
      return this.doc.kind === 99;
    },

    isDeleteBtn() {
      let btn;
      if (this.doc.kind === 7)
        btn =
          this.loginUser.grade >= 2
            ? !(this.doc.id && this.doc.status === "40" && this.doc.isModify)
            : !(this.doc.status === "20" || this.doc.status === "30") &&
              !(this.doc.id && this.doc.status === "40" && this.doc.isModify);
      else if (this.doc.kind === 11) btn = !(!this.doc.isModify && this.doc.id);
      else {
        btn =
          this.loginUser.grade >= 2
            ? !(this.doc.id && this.doc.status === "40" && this.doc.isModify)
            : !(
                this.doc.id &&
                (this.doc.status === "40" ||
                  this.doc.status === "20" ||
                  this.doc.status === "30") &&
                this.doc.isModify
              );
      }
      return btn;
    },

    isSaveBtn() {
      let btn;
      if (this.doc.kind === 7 || this.doc.kind === 18) {
        btn = !(
          !this.doc.id ||
          ((this.doc.status === "40" || this.doc.status === "30") &&
            this.doc.isModify)
        );
      } else if (this.doc.kind === 11) btn = this.doc.isModify;
      else
        btn = !(
          (!this.doc.id ||
            this.doc.status === "40" ||
            this.doc.status === "30") &&
          this.doc.isModify
        );

      return btn;
    },

    saveBtnLabel() {
      return this.doc.status === "40" ? "수 정" : "저 장";
    },

    userBtnTitle() {
      return this.doc.stockBtn && this.doc.requestDoc.kind === 0
        ? !this.doc._id
          ? "재고 등록"
          : "재고 수정"
        : !this.doc.id || this.doc.status === "30" || this.doc.status === "40"
        ? "승인 요청"
        : "승인요청 수정";
    },

    bakDialog() {
      return !(this.doc.history && this.doc.history.length > 0);
    },
  },

  methods: {
    async initDoc() {
      // await this.$store.dispatch(
      //   "SET_PRODUCTION_ORDER",
      //   this.$store.state.productionOrderForm,
      // );
      // this.doc.kind === 11
      //   ? this.$store.dispatch("APPEARANCE_CLEAR")
      //   : this.doc.kind === 7
      //   ? this.$store.dispatch("MANUFACTURE_CLEAR", "clear")
      //   : this.initRelease();
      // this.$store.dispatch("EDIT_FORM_CLOSE");
    },

    initRelease() {
      // this.$store.dispatch("RELEASEFORM_CLEAR");
      // this.$store.dispatch("SET_DOC_LIST", this.doc.salesBtn);
    },

    deleteDoc() {
      this.$store.dispatch("EDIT_FORM_CLOSE");
      this.$store.commit("setCancelDialog", {
        dialog: true,
        status: this.doc.status,
        kind:
          this.doc.kind === 7
            ? "manufacture"
            : this.doc.kind === 11
            ? "appearance"
            : "release",
        id: this.doc.id,
        isOrder: !!(
          this.doc.kind === 7 &&
          this.doc.isOrder &&
          this.loginUser.grade >= 2
        ),
      });
    },

    saveDoc() {
      if (this.doc.kind === 7 || this.doc.kind === 18)
        this.manufactureNotEntered();
      else this.releaseCheckEmptyData();
    },

    // 가고의뢰서 저장
    manufactureNotEntered() {
      const noInputNames = [];
      if (!this.doc.place_manufacture) {
        this.snackbarOn("수신처를 입력해주세요");
        return;
      }
      if (!this.doc.item) {
        this.snackbarOn("ITEM 을 입력해주세요");
        return;
      }
      if (!this.doc.date_payment) {
        this.snackbarOn("출고 납기 를 입력해주세요");
        return;
      }
      if (!this.doc.unit_price) {
        this.snackbarOn("단가 를 입력해 주세요");
        return;
      }
      this.colorList.forEach((x, i) => {
        if (
          (x.quantity !== undefined && x.quantity !== "") ||
          (x.quantity_sample !== undefined && x.quantity_sample !== "")
        )
          !x.name ? noInputNames.push(i + 1) : "";
      });
      if (noInputNames.length) {
        this.snackbarOn(
          `${noInputNames.join(",")} 번째 열 컬러를 입력해 주세요`,
        );
        return;
      }
      if (this.doc.total_quantity_sum === 0) {
        this.snackbarOn("컬러 수량을 입력해주세요");
        return;
      }

      this.doc.refer = false;
      this.$store.commit("setProductionConfirm");
    },

    releaseCheckEmptyData() {
      if (!this.doc.date_delivery) {
        this.snackbarOn("출고일을 입력해 주세요");
        return;
      }
      if (!this.doc.requestDoc) {
        this.snackbarOn("가공의뢰서 또는 외관 검사서를 입력해주세요");
        return;
      }
      if (!this.doc.place_forward) {
        this.snackbarOn("수신처를 입력해주세요");
        return;
      }

      if (this.doc.kind === 10 && !this.doc.place_receive) {
        this.snackbarOn("입고하실 검사소를 입력해 주세요");
        return;
      }

      if (!this.doc.item) {
        this.snackbarOn("아이템을 입력해주세요");
        return;
      }
      if (this.colorTotal === 0) {
        this.snackbarOn("수량을 입력해 주세요");
        return;
      }

      if (
        this.doc.status !== "50" ||
        (!this.loginUser.grade >= 2 && this.doc.status === "20") ||
        (!this.loginUser.grade >= 2 && this.doc.status === "30")
      )
        this.colorCalculation();

      this.$store.commit("setProductionConfirm");
    },

    colorCalculation() {
      this.$store.commit("setSaveReleaseColor", {
        color_list: this.doc.color_list
          ? this.doc.color_list
          : this.$store.state.purchasCacul.color_list,
        purchase:
          this.doc.kind === 8 || this.doc.kind === 19 || this.doc.kind === 15
            ? this.$store.state.purchasCacul
            : {},
        sales:
          this.doc.kind === 9 || this.doc.kind === 15
            ? this.$store.state.salesCacul
            : {},
        total_quantity: this.doc.fabricBtn
          ? this.doc.color_list[0].quantity
          : this.colorTotal,
      });
    },

    //외관 검사서 저장
    appearanceSave() {
      if (!this.doc.release) {
        this.snackbarOn("검사 의뢰서를 선택해주세요");
        return;
      }
      if (
        this.doc.color_list.reduce(
          (r, c) => r + (+c.sucess_quantity + +c.fail_quantity),
          0,
        ) === 0
      ) {
        this.snackbarOn("검사 수량을 입력해주세요");
        return;
      }
      this.saveAppearance();
    },

    saveAppearance() {
      const saveAppearance = {
        company: this.doc.company,
        kind: 11,
        status: "40", // 문서 상태
        liaison: this.doc.liaison._id,
        date_register: this.doc.date_register, // 작성 날짜
        date_modify: new Date(Date.now()), // 저장 날짜
        release: this.doc.release.id, // 검사 의뢰서 id
        manufacture: this.doc.release.manufacture, // 가공의뢰서 id
        unit_sign: this.doc.unit_sign,
        inspection: this.doc.release.place_receive,
        color_list: this.makeAppearanceColorList(),
      };

      this.$store.dispatch("SAVE_APPEARANCE", saveAppearance);
    },

    makeAppearanceColorList() {
      const colorList = [];
      this.doc.color_list.forEach((x) => {
        if (x.name) {
          const color = {
            name: x.name, // 컬러명
            style: x.style, // 스타일명
            check_quantity: x.check_quantity, // 검사수량
            sucess_quantity: x.sucess_quantity, // 함격 수량
            release_quantity: 0, // 출고된 합격 수량
            remaing_quantity: x.sucess_quantity, // 잔여 합격 수량
            fail_quantity: x.fail_quantity, // 최초 불합격 수량
            release_fail_quantity: 0, // 출고된 불합격 수량
            remaing_fail_quantity: x.fail_quantity, // 잔여 불합격 수량
            indexColorName: x.indexColorName, // 가공의뢰서 color id 값
          };
          colorList.push(color);
        }
      });

      return colorList;
    },

    openBakDialog() {
      this.$store.dispatch("EDIT_FORM_CLOSE");
      this.$store.commit("setBakDocListDialog");
    },

    //snacbarOn
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },
  },
};
