export default [
  {
    text: "Status",
    value: "status",
    width: "30px",
    align: "center",
  },
  {
    text: "Order",
    value: "id.order.order",
    width: "30px",
    align: "center",
  },
  {
    text: "Kind",
    value: "kind",
    width: "30px",
    align: "center",
  },
  {
    text: "담당자",
    value: "liaison.name",
    width: "30px",
    align: "center",
  },
  {
    text: "비고",
    value: "memo",
    width: "100px",
    align: "center",
  },
];
