export default [
  {
    text: "COLOR",
    value: "name",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "수량",
    value: "quantity",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "수량 (샘플)",
    value: "quantity_sample",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "수량 (합)",
    value: "quantity_sum",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "단가",
    value: "amount",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "Surcharge (단위 당)",
    value: "surcharge_dyeing_total",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "Surcharge (Color 당)",
    value: "surcharge_dyeing_unit",
    align: "center",
    width: "100px",
    class: "tableHeaderBody",
  },
  {
    text: "수정",
    align: "center",
    width: "50px",
    sortable: false,
    class: "tableHeaderBody",
  },
  {
    text: "삭제",
    align: "center",
    width: "50px",
    sortable: false,
    class: "tableHeaderRigth",
  },
];
