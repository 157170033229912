<template>
  <v-dialog
    :value="dialog"
    max-width="400px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-card-title>재고 전환</v-card-title>
      </v-toolbar>

      <v-card-text
        style="
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        "
        class="pt-3"
        >선매입 문서를 재고로 변환 하시겠습니까? <br />전환시 해당 수량은
        재고로만 매출 출고가 가능합니다
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="close()" text> 아니오 </v-btn>
        <v-btn color="primary" @click="save()" text> 예 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      dialog: "getIsStockDialog",
    }),
  },
  methods: {
    close() {
      this.$store.commit("setIsStockDialog");
    },
    async save() {
      await this.$store.dispatch("CHANGE_STOCK");
      this.$store.dispatch("PRODUCTION_DIALOG_INIT");
      this.close();
    },
  },
};
</script>
