<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card style="height: 550px">
      <div style="display: flex; align-items: center; max-height: 30px">
        <div
          style="
            margin-left: 15px;
            margin-top: 10px;
            font-size: 13px;
            width: 30%;
          "
        >
          생지 수량 계산
        </div>

        <div style="margin-top: 10px; width: 65%; text-align: right">
          <v-icon color="red" @click="close()"> mdi-close </v-icon>
        </div>
      </div>

      <div
        style="
          background-color: ;
          min-height: 18vh;
          padding-top: 10px;
          padding-left: 10px;
          padding-right: 10px;
        "
      >
        <div style="width: 100%; height: 100%">
          <div class="fabricColorsHeader" style="background-color: #f5f5f5">
            <div
              v-for="col in colorsHeader"
              :key="col._id"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                border-left: thin solid rgba(0, 0, 0, 0.1);
              "
            >
              <div v-if="col.value === 'pluse'">
                <v-icon small @click="colorsRowPluse()">
                  mdi-plus-circle
                </v-icon>
              </div>
              <div v-else>{{ col.text }}</div>
            </div>
          </div>

          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
          >
            <div style="max-height: 16.5vh">
              <div
                v-for="(data, i) in colors"
                :key="i"
                class="fabircColorsBody"
              >
                <div class="fabircCaclationListLeft">
                  <v-text-field
                    v-model="data.name"
                    hide-details
                    flat
                    solo
                    class="fabircCaclationColorName"
                  />
                </div>
                <div class="fabircCaclationListLeft">
                  <v-text-field
                    v-model="data.quantity"
                    hide-details
                    flat
                    solo
                    suffix="y"
                    class="fabircCaclationQuantity"
                    @input="colorQuantityInput(i)"
                  />
                </div>
                <div
                  class="fabircCaclationListLeft"
                  style="border-right: thin solid rgba(0, 0, 0, 0.1)"
                >
                  <v-icon small @click="colorRowsDelete(i)">
                    mdi-delete
                  </v-icon>
                </div>
              </div>
            </div>
          </overlay-scrollbars>
          <div class="fabircColorsBody fabricColorsBottom">
            <div class="fabircCaclationListBottomLeft">발주 수량</div>
            <div class="fabircCaclationListBottomLeft">
              <v-text-field
                v-model="commaOrderQuantity"
                hide-details
                flat
                solo
                readonly
                suffix="y"
                class="fabircCaclationQuantity"
                @input="inputOrderQuantity($event)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          background-color: ;
          min-height: 25vh;
          padding-top: 10px;
          padding-left: 10px;
          padding-right: 10px;
        "
      >
        <div style="width: 100%; height: 100%">
          <div
            class="fabircCaclationListHeader"
            style="background-color: #f5f5f5"
          >
            <div
              v-for="col in header"
              :key="col._id"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                border-left: thin solid rgba(0, 0, 0, 0.1);
              "
            >
              <div v-if="col.value !== 'pluse'">
                {{ col.text }}
              </div>
              <div v-else>
                <v-icon small @click="processingSequenceRowPluse()">
                  mdi-plus-circle
                </v-icon>
              </div>
            </div>
          </div>
          <!-- <div class="fabircCaclationListBottom">
            <div class="fabircCaclationListBottomLeft">발주 수량</div>
            <div class="fabircCaclationListBottomLeft">
              <v-text-field
                v-model="commaOrderQuantity"
                hide-details
                flat
                solo
                suffix="y"
                class="fabircCaclationQuantity"
                @input="inputOrderQuantity($event)"
              />
            </div>
            <div class="fabircCaclationListBottomLeft"></div>
          </div> -->
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
          >
            <div style="max-height: 20vh">
              <div
                v-for="(data, i) in processingSequence"
                :key="i"
                class="fabircCaclationListBody"
              >
                <div class="fabircCaclationListLeft">
                  {{ processStep(data, i) }}
                </div>
                <div class="fabircCaclationListLeft">
                  <v-combobox
                    v-model="data.company"
                    class="fabircCaclationColorName"
                    hide-details
                    flat
                    solo
                    return-object
                    item-text="name"
                    :items="clientList"
                    @change="changeCompmany(i, $event)"
                  />
                </div>
                <div class="fabircCaclationListLeft">
                  <v-text-field
                    v-model="data.quantity"
                    hide-details
                    flat
                    solo
                    readonly
                    suffix="y"
                    class="fabircCaclationQuantity"
                  />
                </div>
                <div class="fabircCaclationListLeft">
                  <v-text-field
                    v-model="data.shrigkage"
                    hide-details
                    flat
                    solo
                    type="number"
                    suffix="%"
                    :disabled="isShrigkage(i)"
                    class="fabircCaclationQuantity"
                    @input="inputShrigkage(i, $event)"
                  />
                </div>
                <div class="fabircCaclationListLeft">
                  <v-text-field
                    v-model="data.shrigkageQuantity"
                    hide-details
                    flat
                    solo
                    suffix="y"
                    readonly
                    class="fabircCaclationQuantity"
                  />
                </div>
                <div
                  class="fabircCaclationListLeft"
                  style="border-right: thin solid rgba(0, 0, 0, 0.1)"
                >
                  <v-icon small @click="processingSequenceRowsDelete(i)">
                    mdi-delete
                  </v-icon>
                </div>
              </div>
            </div>
          </overlay-scrollbars>
          <div class="fabircCaclationListBottom">
            <div class="fabircCaclationListBottomLeft"></div>
            <div class="fabircCaclationListBottomLeft">
              <v-combobox
                v-model="fabrricCompany"
                class="fabircCaclationColorName"
                hide-details
                flat
                solo
                return-object
                item-text="name"
                label="생지 업체"
                :items="clientList"
              />
            </div>
            <div class="fabircCaclationListBottomLeft"></div>
            <div class="fabircCaclationListBottomLeft">생지 수량</div>
            <div class="fabircCaclationListBottomLeft">
              <v-text-field
                v-model="shrigkageQuantity"
                hide-details
                flat
                solo
                suffix="y"
                class="fabircCaclationQuantity"
              />
            </div>
            <div class="fabircCaclationListBottomLeft"></div>
          </div>
        </div>
        <div style="width: 100%; text-align: right; margin-top: 5px">
          <v-btn color="primary" @click="saveDataInputCheck()" small>
            가공 단계 생성
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { putOrder } from "@/api/order";

export default {
  data() {
    return {
      orderQuantity: "",
      shrigkageQuantity: "",
      saveOrder: "",
      fabrricCompany: "",
      header: [
        {
          text: "단계",
          value: "process",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "업체",
          value: "company",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "수량",
          value: "quantity",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "축률",
          value: "shrigkage",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "수량(축률)",
          value: "shrigkageQuantity",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          value: "pluse",
          align: "center",
          class: "tableHeaderBody",
        },
      ],

      colorsHeader: [
        {
          text: "컬러",
          value: "name",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "수량",
          value: "quantity",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "pluse",
          value: "pluse",
          align: "center",
          class: "tableHeaderBody",
        },
      ],

      colors: [
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
      ],

      processingSequence: [
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      dialog: "getFabricCaculateDialog",
      clientList: "getUseClientList",
      release: "getReleaseForm",
    }),

    commaOrderQuantity() {
      return this.usingComma(this.orderQuantity);
    },
  },
  methods: {
    processStep(data, i) {
      return i === 0
        ? "최종가공"
        : data.shrigkage
        ? `${
            this.processingSequence.filter((x) => x.shrigkage).length +
            (this.processingSequence[0].shrigkage ? 0 : 1) -
            i
          } 번째 가공 `
        : "";
    },

    isShrigkage(i) {
      return i !== 0 ? !this.processingSequence[i - 1].shrigkage : false;
    },

    colorQuantityInput(i) {
      this.orderQuantityCaculation();
      this.colors[i].quantity = this.usingComma(this.colors[i].quantity);
      this.colors[i].indexColorName = `${i + 1}_${this.colors[i].name}`;
      this.shrigkageQuantityCaculation();
    },

    orderQuantityCaculation() {
      this.orderQuantity = this.colors.reduce(
        (r, c) => r + +(c.quantity ? this.parsingNum(c.quantity) : 0),
        0,
      );
    },

    inputOrderQuantity(orderQuantity) {
      this.orderQuantity = this.usingComma(orderQuantity);
      this.shrigkageQuantityCaculation();
    },
    changeCompmany(i, data) {
      this.processingSequence[i].company = data;
    },
    inputShrigkage(i, shrigkage) {
      this.processingSequence[i].shrigkage = shrigkage;
      this.shrigkageQuantityCaculation();
    },

    shrigkageQuantityCaculation() {
      let shrigkageQuantity = "";
      this.processingSequence = this.processingSequence.map((x, i) => {
        const data = x;
        if (i === 0) data.quantity = this.orderQuantity;
        else data.quantity = shrigkageQuantity;

        if (data.shrigkage) {
          const quantity = this.roundsData(
            this.shrigkageCacul(
              this.parsingNum(data.quantity),
              +data.shrigkage,
            ),
          );
          shrigkageQuantity = this.usingComma(quantity);
          data.shrigkageQuantity = shrigkageQuantity;
          // data.color_list = this.setManufactureColorList(i, data.shrigkage);
        } else {
          data.shrigkageQuantity = "";
          shrigkageQuantity = "";
        }

        return data;
      });
      this.setShrigkageQuantity();
    },

    setShrigkageQuantity() {
      const process = this.copyData(this.processingSequence)
        .reverse()
        .find((x) => x.shrigkageQuantity);

      this.shrigkageQuantity = process ? process.shrigkageQuantity : "";
    },

    colorsRowPluse() {
      this.colors.push({
        name: "",
        quantity: "",
      });
    },

    colorRowsDelete(i) {
      this.colors.splice(i, 1);
      if (this.colors.length < 5) this.colorsRowPluse();
      this.orderQuantityCaculation();
    },

    processingSequenceRowPluse() {
      this.processingSequence.push({
        company: "",
        shrigkage: "",
        quantity:
          this.processingSequence[this.processingSequence.length - 1]
            .shrigkageQuantity,
        shrigkageQuantity: "",
      });
    },

    processingSequenceRowsDelete(i) {
      this.processingSequence.splice(i, 1);
      if (this.processingSequence.length < 6) this.processingSequenceRowPluse();
      this.shrigkageQuantityCaculation();
    },

    close() {
      this.$store.commit("setFabricCaculateDialog");
      this.orderQuantity = "";
      this.shrigkageQuantity = "";
      this.processingSequence = [];
      this.fabrricCompany = "";
      this.colors = [];

      for (let i = 0; i < 6; i++) {
        this.processingSequence.push({
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        });
        if (i < 5)
          this.colors.push({
            name: "",
            quantity: "",
          });
      }
    },

    saveDataInputCheck() {
      if (!this.orderQuantity) {
        this.snackbarOn("가공수량을 입력해주세요.");
        return;
      }
      if (this.colors.find((x) => !x.name && x.quantity)) {
        this.snackbarOn("컬러 명을 입력해주세요");
        return;
      }

      if (!this.shrigkageQuantity) {
        this.snackbarOn("주문할 생지를 입력해 주세요");
        return;
      }

      this.save();
    },

    async save() {
      this.$store.commit("setAPILoading", true);
      await this.setSaveOrderForm();
      await this.saveApiAction({
        action: putOrder,
        saveData: this.saveOrder,
      });
      await this.$store.dispatch("PRODUCTION_ORDER_CHANGE", this.saveOrder);
      this.close();
      this.$store.commit("setAPILoading", false);
    },

    async setSaveOrderForm() {
      await this.$store.dispatch(
        "SELECT_ORDER",
        this.$store.state.productionOrderForm,
      );
      this.saveOrder = this.copyData(this.$store.state.selectOrder);
      this.$store.commit("setSelectOrder");
      this.saveOrder.release.push(this.setRelease());
      this.setOrderManufacture();
    },

    createProgressData(data) {
      this.saveOrder.progress.push({
        status: data.status, //  승인상태
        date_register: data.date_register,
        user: this.$store.state.auth_name,
        kind: data.kind, // 의뢰서 종류
        memo: "", // 메모
        log: `임시 ${data.kind === 19 ? "출고" : "가공"}의뢰서 (${
          data.client ? data.client.name : "업체 미지정"
        })`,
        id: data.id, // 문서 id
        history: [],
      });
    },

    setRelease() {
      const release = {
        company: this.saveOrder.company,
        order: this.saveOrder.order,
        liaison: this.$store.state.auth_id,
        kind: 19,
        status: "70",
        unit_sign: this.saveOrder.unit_sign,
        date_register: new Date(Date.now()), // 작성 날짜
        date_modify: new Date(Date.now()), // 저장 날짜
        item: this.saveOrder.item,
        id: this.productionIdMake(
          this.saveOrder.order,
          "release",
          this.saveOrder.release.length,
        ),
        color_list: [
          { name: "생 지", quantity: this.parsingNum(this.shrigkageQuantity) },
        ],
        purchase: {
          quantity: this.parsingNum(this.shrigkageQuantity),
          color_list: [
            {
              name: "생 지",
              quantity: this.parsingNum(this.shrigkageQuantity),
            },
          ],
          unit_sign: this.saveOrder.unit_sign,
          currency_sign: this.saveOrder.currency_sign
            ? this.saveOrder.currency_sign
            : "₩",
        },
      };
      if (this.fabrricCompany) release.place_forward = this.fabrricCompany._id;
      this.createProgressData({ ...release, client: this.fabrricCompany });
      return release;
    },

    setOrderManufacture() {
      const manufactures = this.createManufactures();
      this.saveOrder.manufacture = [
        ...this.saveOrder.manufacture,
        ...this.modifyManufactures(manufactures),
      ];
    },

    modifyManufactures(manufactures) {
      return manufactures.map((x, i) => {
        const manufacture = x;
        manufacture.referenceDoc =
          i === 0
            ? this.saveOrder.release[this.saveOrder.release.length - 1].id
            : manufactures[i - 1].id;
        this.createProgressData({ ...manufacture });
        return manufacture;
      });
    },

    createManufactures() {
      let previousProcessColors = this.colors
        .filter((x) => x.quantity)
        .map((x) => ({ ...x, quantity_shrigkage: x.quantity }));

      const manufactures = this.processingSequence
        .filter((x) => x.shrigkage)
        .map((process, i) => {
          const manufacture = this.setManufacture(
            process,
            previousProcessColors,
            this.processingSequence.filter((x) => x.shrigkage).length - 1 - i,
          );
          previousProcessColors = manufacture.color_list;
          return manufacture;
        })
        .reverse();
      return manufactures;
    },

    setManufacture(process, previousProcessColors, i) {
      const manufacture = {
        company: this.saveOrder.company,
        order: this.saveOrder.order,
        liaison: this.$store.state.auth_id,
        kind: 7,
        status: "70",
        unit_sign: this.saveOrder.unit_sign,
        currency_sign: this.saveOrder.currency_sign
          ? this.saveOrder.currency_sign
          : "₩",
        date_register: new Date(Date.now()), // 작성 날짜
        date_modify: new Date(Date.now()), // 저장 날짜
        shrigkage: +process.shrigkage,
        item: this.saveOrder.item,
        id: this.productionIdMake(
          this.saveOrder.order,
          "manufacture",
          this.saveOrder.manufacture.length + i,
        ),
        color_list: this.setManufactureColorList(
          previousProcessColors,
          +process.shrigkage,
        ),
        client: process.company,
      };
      if (process.company) manufacture.place_manufacture = process.company._id;
      return manufacture;
    },

    setManufactureColorList(previousProcessColors, shrigkage) {
      return previousProcessColors.map((color, i) => {
        return {
          name: color.name,
          indexColorName: previousProcessColors[i].indexColorName,
          quantity: this.parsingNum(color.quantity_shrigkage),
          quantity_shrigkage: this.shrigkageCacul(
            this.parsingNum(color.quantity_shrigkage),
            shrigkage,
          ),
        };
      });
    },

    //snacbarOn
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },
  },
};
</script>
