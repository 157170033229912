export default [
  {
    text: "STYLE",
    value: "styleNo",
    align: "center",
    width: "170px",
    class: "tableHeaderBody",
  },
  {
    text: "COLOR",
    value: "color",
    align: "center",
    width: "150px",
    class: "tableHeaderBody",
  },
  {
    text: "수량",
    value: "quantity",
    align: "center",
    width: "150px",
    class: "tableHeaderBody",
  },
  {
    text: "삭제",
    align: "center",
    width: "50px",
    class: "tableHeaderRigth",
  },
];
