<template>
  <div style="margin-top: 2%">
    <ChangeDocForm />
    <OrderDocForm v-if="selectDocData.kind === 0" />
    <ManufactureDocForm v-if="selectDocData.kind === 7" />
    <ReleaseDocForm
      v-if="
        selectDocData.kind === 8 ||
        selectDocData.kind === 9 ||
        selectDocData.kind === 10 ||
        selectDocData.kind === 15 ||
        selectDocData.kind === 16
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import OrderDocForm from "@/components/organisms/docDataForm/orderDocForm.vue";
import ManufactureDocForm from "@/components/organisms/docDataForm/manufactureDocForm.vue";
import ChangeDocForm from "@/components/organisms/docDataForm/changeDocForm.vue";
import ReleaseDocForm from "@/components/organisms/docDataForm/releaseDocForm.vue";
export default {
  components: {
    OrderDocForm,
    ManufactureDocForm,
    ChangeDocForm,
    ReleaseDocForm,
  },
  computed: {
    ...mapGetters({
      selectDocData: "getSelectDocData",
    }),
  },
};
</script>
