<template>
  <v-dialog
    v-model="selectRelease.dialog"
    max-width="900px"
    scrollable
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2 pb-0 pt-0">
        {{ title }}
      </v-card-title>
      <v-card-text style="height: 1200px">
        <div id="printRelease">
          <v-row class="print mb-4 px-8">
            <v-col cols="12" class="px-0">
              <v-col
                cols="12"
                class="text-h5 font-weight-black b-0 px-0 pb-0 pt-0"
              >
                <span
                  class="d-flex justify-center"
                  style="text-transform: uppercase"
                >
                  {{ $store.state.auth_company }}
                </span>
              </v-col>
              <v-col cols="12" class="pb-0 px-0">
                <span class="d-flex justify-center body-2">
                  {{ `${company.address ? company.address : ""}` }}
                  {{ `TEL : ${company.tel ? getMask(company.tel) : ""}` }}
                  {{ `FAX : ${company.fax ? getMask(company.fax) : ""}` }}
                </span>
              </v-col>
              <hr class="printHr" />
              <hr style="border: solid 2px" />
              <v-col cols="6" class="pb-0 px-0">
                <span class="receptionText">
                  수 신 :
                  {{
                    selectRelease.place_forward
                      ? selectRelease.place_forward.name
                      : ""
                  }}
                </span>
              </v-col>
              <v-col cols="6" class="pb-0 px-0">
                <span class="receptionText">
                  발 신 :
                  {{
                    `${
                      company.name
                        ? `㈜ ${company.id} / ${
                            selectRelease.liaison
                              ? selectRelease.liaison.name
                              : ""
                          } `
                        : ""
                    }`
                  }}
                </span>
              </v-col>
              <v-col cols="12" class="pb-4 px-0">
                <v-row>
                  <v-col cols="6" class="pb-0 px-0 pl-3">
                    <span class="receptionText"> 참 조 : 원단 출고의뢰서 </span>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pb-0 px-0 text-end pr-4 font-weight-black"
                  >
                    {{ $store.state.Today }}
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
            <v-col cols="12" class="pa-0">
              <hr />
            </v-col>
            <v-col
              cols="12"
              class="pb-0 d-flex justify-center releasePrintHeadTitle"
            >
              <!-- <div>{{ selectType }}</div> -->
            </v-col>
          </v-row>
          <!-- <v-col cols="12" class="px-6 printForm"> -->
          <v-col>
            <v-row class="px-8">
              <v-col cols="12" class="pt-0">
                <span class="subtitle-1">항상 많은 도움에 감사 드립니다.</span>
              </v-col>
              <v-col cols="12" class="pt-0">
                <span class="subtitle-1"
                  >하기 스타일의 출고/검단 협조 부탁 드립니다.</span
                >
              </v-col>
              <v-col cols="12" class="pb-6">
                <p class="subtitle-1 text-center">
                  {{ `==== 下` }}&nbsp;&nbsp;&nbsp;&nbsp;{{ `記 ====` }}
                </p>
              </v-col>
              <v-row style="height: 400px" class="px-4">
                <v-col cols="6" class="pl-0 pt-0 pr-0">
                  <v-row>
                    <v-col cols="4" class="pl-6">
                      <span class="releasePrintBodyTitle">- ORDER NO </span>
                    </v-col>
                    <v-col cols="8">
                      <span class="body-2">: {{ selectRelease.order }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pl-6">
                      <span class="releasePrintBodyTitle">- ITEM NO </span>
                    </v-col>
                    <v-col cols="8">
                      <span class="body-2"
                        >:
                        {{ selectRelease.item ? selectRelease.item.name : "" }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pl-6">
                      <span class="releasePrintBodyTitle">- FEEL NO </span>
                    </v-col>
                    <v-col cols="8">
                      <span class="body-2"
                        >:
                        {{
                          selectRelease.item
                            ? selectRelease.item.name_production
                            : ""
                        }}</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pl-6">
                      <span class="releasePrintBodyTitle">- STYLE NO </span>
                    </v-col>
                    <v-col cols="8">
                      <span class="body-2">: {{ styles }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pl-6">
                      <span class="releasePrintBodyTitle">- WIDTH </span>
                    </v-col>
                    <v-col cols="8">
                      <span class="body-1"
                        >:
                        {{
                          selectRelease.id
                            ? `${
                                selectRelease.item.width
                                  ? selectRelease.item.width
                                  : ""
                              }"`
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-row>
                    <v-col class="">
                      <v-simple-table>
                        <tr>
                          <th class="releaseRightHeadTitle borderAllBlack">
                            출고 수량
                          </th>
                        </tr>
                        <tr class="">
                          <td class="borderAllBlackTd">
                            <span class="d-flex justify-center">
                              {{
                                `브랜드 : ${
                                  selectRelease.client
                                    ? selectRelease.client.name
                                    : ""
                                }`
                              }}
                            </span>
                          </td>
                        </tr>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                  <v-col
                    v-for="(color, index) in $store.state.prientColor"
                    :key="color._id"
                  >
                    <v-row>
                      <v-col cols="1" />
                      <v-col cols="4">
                        <span class="">{{
                          `${index + 1}. ${color.name} :`
                        }}</span>
                      </v-col>
                      <v-col cols="2" />
                      <v-col cols="5">
                        <span class="">{{
                          `${
                            color.success_quantity
                              ? color.success_quantity
                              : color.quantity
                          }${selectRelease.id ? selectRelease.unit_sign : ""}`
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="borderTopDouble">
                    <v-row>
                      <v-col cols="1" />
                      <v-col cols="3">
                        <span class="">{{ `TOTAL : ` }}</span>
                      </v-col>
                      <v-col cols="3" />
                      <v-col cols="5">
                        <span class="">{{
                          `${
                            selectRelease.total_quantity
                              ? selectRelease.total_quantity
                              : ""
                          }${selectRelease.id ? selectRelease.unit_sign : ""}`
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-row>
            </v-row>
            <v-row class="px-6" style="display: inline">
              <v-row>
                <v-col cols="2" class="pl-12">
                  <span class="releasePrintBodyTitle">- 입고처</span>
                </v-col>
                <v-col cols="9" class="pl-6">
                  <v-row>
                    <v-col cols="1" class=""> : </v-col>
                    <v-col cols="11" class="pa-0 ma-0">
                      <v-textarea
                        rows="4"
                        :value="selectRelease.id ? placefoward : ''"
                        no-resize
                        clearable
                        :outlined="false"
                        clear-icon="mdi-close-circle"
                        style="overflow-x: hidden; overflow-y: hidden"
                        hide-details
                        class="mt-0"
                      >
                        <template v-slot:append="{ value }">
                          {{ value }}
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
            <v-row class="px-6" style="display: inline">
              <v-row>
                <v-col cols="2" class="pl-12">
                  <span class="releasePrintBodyTitle">- 주의사항</span>
                </v-col>
                <v-col cols="9" class="pl-6">
                  <v-row>
                    <v-col cols="1" class=""> : </v-col>
                    <v-col cols="11" class="pa-0 ma-0">
                      <v-textarea
                        rows="6"
                        no-resize
                        clearable
                        :outlined="false"
                        clear-icon="mdi-close-circle"
                        style="overflow-x: hidden; overflow-y: hidden"
                        hide-details
                        class="mt-0"
                        :value="precautions"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$store.commit('setReleasePrient')" text>
          닫 기
        </v-btn>
        <v-btn color="primary" @click="print()" text> 출 력 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import pritnerFormRightHeader from "@/assets/table/print/pritnerFormRightHeader";
import Printjs from "print-js";
import { mapGetters } from "vuex";
export default {
  // props: {
  //   selectType: {},
  //   selectRelease: {},
  // },
  computed: {
    ...mapGetters({
      selectOrder: "getForm",
      company: "getCompany",
      selectRelease: "getReleasePrien",
      // client: "getClientList",
    }),
    dialog_release_print() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "releasePrint"
        ? true
        : false;
    },
    placefoward() {
      const client = this.selectRelease.place_receive;
      return `${client.name} / ${client.liaison ? client.liaison : ""} (${
        client.tel ? this.getMask(client.tel) : ""
      })\n${client.address ? client.address : ""} `;
    },
    precautions() {
      return this.selectRelease.precautions
        ? this.selectRelease.precautions
        : "";
    },
    styles() {
      return this.$store.state.prientColor
        ? this.$store.state.prientColor
            .map((x) => x.style)
            .filter((x) => x !== undefined)
            .join(",")
        : "";
    },
    title() {
      return this.selectRelease.id
        ? this.selectRelease.kind === 10
          ? "검사 의뢰서"
          : "출고의뢰서"
        : "";
    },
  },
  data() {
    return {
      pritnerFormRightHeader,
      // company: this.$store.state.company,
    };
  },
  methods: {
    styleNames() {
      const styleList = [];
      this.release.color.filter((x) =>
        x.style ? styleList.push(x.style) : "",
      );
      return styleList.join(", ");
    },

    print() {
      Printjs({
        printable: "printRelease",
        type: "html",
        css: "./static/index.css",
        targetStyles: ["*"],
        header: null,
        maxWidth: 1600,
        scanStyles: true,
        font_size: ".printHeadTitle : 35px; ",
      });
    },
  },
};
</script>
