<template>
  <v-dialog
    v-model="openDialog"
    max-width="600px"
    persistent
    no-click-animation
    @keydown.esc="releaseUnsave()"
  >
    <v-card @keypress.enter="releaseSave()">
      <v-card-title>
        <span> {{ titleReleaseeSave }} </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                :value="release.place_forward ? release.place_forward.name : ''"
                disabled
                label="출고처"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                label="저장 날짜"
                :value="
                  release.date_register
                    ? this.dateFormat(release.date_register)
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                label="출고날짜"
                :value="
                  release.date_delivery
                    ? this.dateFormat(release.date_delivery)
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="$store.state.auth_grade <= 2">
              <v-text-field
                label="비고"
                v-model="$store.state.approvalMemo"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col
            style="
              color: red;
              font-size: 15px;
              border: thin solid rgba(0, 0, 0, 0.5);
              border-radius: 5px;
            "
            >* VAT(매입/매출) 미포함 여부를 꼭 확인하세요</v-col
          >
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="releaseUnsave">
          아니오
        </v-btn>

        <v-btn
          v-if="!release.companionCheck"
          color="blue darken-1"
          text
          @click="releaseSave"
        >
          예
        </v-btn>
        <v-btn v-else color="blue darken-1" text @click="releaseSave">
          반려
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      memo: "",
      saveOrder: "",
    };
  },
  computed: {
    ...mapGetters({
      release: "getReleaseForm",
      order: "getProductionForm",
    }),
    titleReleaseeSave() {
      return !this.release.companionCheck
        ? this.release.kind === 17 && this.release.requestDoc.kind === 0
          ? this.release._id
            ? "재고 수정"
            : "재고 등록"
          : this.$store.state.auth_grade >= 2
          ? "출고의뢰서 승인"
          : "출고의뢰서 승인 요청"
        : "출고의뢰서 반려";
    },
    openDialog() {
      return this.release.dialog && this.$store.state.confirmDialog;
    },
  },
  methods: {
    releaseUnsave() {
      this.$store.commit("setProductionConfirm");
      this.memo = "";
    },
    releaseSave() {
      if (this.release.fabricBtn) {
        this.release.status === "70"
          ? this.$store.dispatch("SAVE_TEMPORARY_FABIRC_SAVE", this.release)
          : this.fabricSave();
      } else {
        if (this.release.kind === 17 || this.release.kind === 12)
          this.$store.dispatch("STOCK_VERSION_CHECK", this.release.id);
        else if (this.release.requestDoc.kind === 17)
          this.$store.dispatch("REQUEST_STOCK_VERSION_CHECK");
        else this.$store.dispatch("SAVE_PARSE_DATA", "production");
      }
    },

    //생지 저장 기능 - 수정예정
    async fabricSave() {
      this.$store.commit("setAPILoading", true);
      await this.$store.dispatch("SELECT_ORDER", this.order);
      if (this.order.__v !== this.$store.state.selectOrder.__v) return;
      if (this.release.fabricBtn) await this.setReleaseSaveData();
      if (this.release.fabricBtn && this.release._id) this.modifyReleaseData();

      this.$store.commit("setSaveData", this.saveOrder);
      this.$store.dispatch("PRODUCTION_DIALOG_INIT");
      await this.$store.dispatch("SAVE_ORDER");
      this.releaseUnsave();
    },

    setReleaseSaveData() {
      this.saveOrder = this.$store.state.selectOrder;
      const saveRelease = this.releaseParse();

      if (saveRelease.status !== "50") {
        this.saveOrder.release.push(saveRelease);
        this.saveOrder.progress = this.setOrderPorgress(saveRelease);
      }

      this.$store.dispatch(
        "SET_APPROVAL_SAVE_DATA",
        saveRelease.status === "50"
          ? { ...saveRelease, modifyData: saveRelease }
          : saveRelease,
      );
    },

    modifyReleaseData() {
      const index = this.saveOrder.progress.findIndex(
        (x) => x.id === this.release.id,
      );
      const release = this.saveOrder.release.find(
        (x) => x._id === this.release._id,
      );

      release.status = 2 <= this.$store.state.auth_grade ? "99" : "50";

      if (release.status === "99") {
        release.id = `${release.id}_bak_${this.saveOrder.release.length}`;
        this.saveOrder.progress[index].history.push({
          id: release.id,
          log: history.length,
          date: new Date(Date.now()),
        });
      } else this.saveOrder.progress[index].status = release.status;
    },

    setOrderPorgress(doc) {
      const progress = this.saveOrder.progress;
      if (!this.release._id) {
        progress.push(this.newProgress(doc));
      } else {
        const index = progress.findIndex((x) => x.id === doc.id);
        progress[index] = this.newProgress(doc);
      }
      return progress;
    },

    newProgress(doc) {
      const progress = {
        status: doc.status,
        date_register: doc.date_register,
        user: this.release.liaison.name,
        kind: doc.kind,
        memo: "",
        log: this.progressLogMake(),
        id: doc.id,
        history: [],
      };
      return progress;
    },

    progressLogMake() {
      let log = "";
      if (this.release.kind === 7 || this.release.kind === 18)
        log = "가공의뢰서 매입";

      if (this.release.kind === 19 || this.release.kind === 8)
        log = `${this.release.place_forward.name} -> ${
          this.release.place_receive
            ? this.release.place_receive.name
            : "미지정"
        }`;

      if (this.release.kind === 9 || this.release.kind === 15)
        log = `${this.release.place_forward.name} -> ${
          this.release.place_sales ? this.release.place_sales.name : "미지정"
        }`;

      return log;
    },

    releaseParse() {
      const color = this.$store.state.saveReleaseColor;
      const release = {
        company: this.$store.state.auth_company, // ref company.id
        id: this.release.id
          ? this.release.id
          : this.productionIdMaker("release"), // 고유 값
        order_kind: 1,
        liaison: this.release.liaison._id, // 담당자
        kind: this.release.kind, // 검사서 종류
        unit_sign: this.release.unit_sign, // 수량 단위
        date_register: this.release.date_register, // 작성 날짜
        date_modify: new Date(Date.now()), // 저장 날짜
        date_delivery: this.release.date_delivery,
        total_quantity: this.$store.state.colorTotal,
        order: this.order.order,
        place_receive_memo: this.release.place_receive_memo, //입고지 메모
        remark: this.release.remark,
        manufacture:
          this.release.requestDoc.kind === 7 ||
          this.release.requestDoc.kind === 18
            ? this.release.requestDoc.id
            : "", // 가공의뢰서
        precautions: this.release.precautions, // 주의사항
        item: this.release.item._id, // 아이템
        place_forward: this.release.place_forward._id, // 출고처
        status: this.discretionStatus(),
        color_list: color.colorList,
        purchase: color.purchase,
        sales: color.sales,
      };
      if (this.release.place_receive)
        release.place_receive = this.release.place_receive._id;
      return release;
    },

    discretionStatus() {
      return 2 <= this.$store.state.auth_grade
        ? "40"
        : this.release._id
        ? "50"
        : "20";
    },

    productionIdMaker(kind) {
      return `${this.order.order}_${kind}_${this.numFromat(kind)}`;
    },
    numFromat(kind) {
      const num =
        kind === "release"
          ? this.order.release.length + 1
          : this.order.manufacture.length + 1;
      return 10 > num ? `00${num}` : 100 > num ? `0${num}` : `${num}`;
    },
  },
};
</script>
