<template>
  <v-dialog v-model="isDoalog" width="600px" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Surcharge (컬러)
      </v-card-title>
      <v-card-text class="pt-2">
        <div class="releaseSurchargeHeader">
          <div class="text-bold releasecolorListHeaderTitle">No</div>
          <div class="text-bold releasecolorListHeaderTitle">STYLE</div>
          <div class="text-bold releasecolorListHeaderTitle">COLOR</div>
          <div class="text-bold releasecolorListHeaderTitle">수량</div>
          <div class="text-bold releasecolorListHeaderTitleRight">
            Surcharge
          </div>

          <div class="releaseSurchargeBodyGrid">
            <overlay-scrollbars
              :options="{
                overflowBehavior: {
                  x: 'hidden',
                },
              }"
              style="min-width: 100%"
            >
              <div
                v-for="(color, i) in colorList"
                :key="color._id"
                class="releaseSalesColorListBody"
              >
                <div class="colorSurchargeList surchargeColorLeft">
                  {{ i + 1 ? i + 1 : "" }}.
                </div>
                <div class="colorSurchargeList">
                  {{ color.style }}
                </div>
                <div class="colorSurchargeList">
                  {{ color.name }}
                </div>
                <div class="colorSurchargeQuantity">
                  {{
                    ((color.success_quantity
                      ? color.success_quantity
                      : color.quantity
                      ? color.quantity
                      : "") +
                      release.unit_sign)
                      | makeComma
                  }}
                </div>
                <div class="colorSurchargeRight">
                  <v-text-field
                    v-if="color.name"
                    v-model="color.surchargeCommma"
                    hide-details
                    flat
                    solo
                    :prefix="release.currency_sign"
                    class="releaseColorStyle manufactureTitle"
                    @input="surchargeCacul($event, i)"
                  />
                </div>
              </div>
            </overlay-scrollbars>
          </div>
          <div class="salesSurchargeTot" style="grid-column: span 4">Total</div>
          <div
            class="salesSurchargeQuantity"
            style="grid-column: span 1; justify-content: right"
          >
            {{
              this.release.salesBtn && this.release.color_surcharge_unit
                ? this.release.currency_sign + this.release.color_surcharge_unit
                : ""
            }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> 닫기 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sales_total_amount: 0,
    };
  },
  computed: {
    ...mapGetters({
      isDoalog: "getIsSalesSurcharge",
      colorList: "getProductionColor",
      release: "getSalesSurchargeColorRelease",
    }),
  },

  methods: {
    //surcharge 입력시 계산 로직
    surchargeCacul(number, i) {
      this.colorList[i].surcharge_unit = this.parsingNum(number);
      this.colorList[i].surchargeCommma = this.usingComma(
        this.colorList[i].surcharge_unit,
      );

      this.release.color_surcharge_unit = this.usingComma(
        this.colorList.reduce(
          (r, c) => r + (c.surcharge_unit ? +c.surcharge_unit : 0),
          0,
        ),
      );

      this.$store.dispatch("CALCULATE_RELEASE_SALES");
    },
    close() {
      this.$store.commit("setIsSalesSurcharge");
    },
  },
};
</script>
