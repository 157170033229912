<template>
  <v-dialog
    :value="openDialog"
    max-width="600px"
    persistent
    no-click-animation
    @keydown.esc="manufactureUnsave()"
  >
    <v-card @keypress.enter="manufactureSave()">
      <v-card-title>
        <span>
          {{
            this.$store.state.manufactureForm.refer
              ? "반 려"
              : this.$store.state.auth_grade >= 2
              ? "승 인"
              : this.$store.state.manufactureForm.status === "20"
              ? "승인 요청 수정"
              : "승인 요청"
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                disabled
                label="가공처"
                :value="
                  manufacture.place_manufacture
                    ? manufacture.place_manufacture.name
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                label="저장 날짜"
                :value="
                  manufacture.date_register
                    ? this.dateFormat(manufacture.date_register)
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                disabled
                label="납기 날짜"
                :value="
                  manufacture.date_payment
                    ? this.dateFormat(manufacture.date_payment)
                    : ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="$store.state.auth_grade <= 2">
              <v-text-field
                label="비고"
                v-model="$store.state.approvalMemo"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="addStyleColor.whether && !manufacture.companionCheck"
              cols="12"
            >
              <v-card min-width="500">
                <v-card-title class="text-subtitle-1">
                  <v-icon class="pr-2" color="green"
                    >mdi-information-outline</v-icon
                  >
                  승인 시 오더에 없는 style과 color가 목록 추가됩니다.
                </v-card-title>
                <v-divider class="ml-2 mr-2"></v-divider>
                <v-card-text>
                  <v-row style="font-size: 15px">
                    <v-col cols="2">COLOR : </v-col>
                    <v-col cols="10"
                      >{{ `${addStyleColor.colorList.join(",  ")}` }}
                    </v-col>
                    <v-col cols="2"> STYLE : </v-col>
                    <v-col cols="10">{{
                      `${addStyleColor.styleList.join(",  ")}`
                    }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="manufactureUnsave()">
          아니오
        </v-btn>
        <v-btn
          v-if="!manufacture.refer"
          color="blue darken-1"
          text
          @click="manufactureSave()"
        >
          예
        </v-btn>
        <v-btn v-else color="blue darken-1" text @click="manufactureSave()">
          반려
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      addStyleColor: {},
      memo: "",
    };
  },
  computed: {
    ...mapGetters({
      manufacture: "getManufactureForm",
    }),
    openDialog() {
      return this.manufacture.dialog && this.$store.state.confirmDialog;
    },
  },
  methods: {
    manufactureUnsave() {
      this.$store.commit("setProductionConfirm");
      this.memo = "";
    },
    manufactureSave() {
      if (this.manufacture.status === "70")
        this.$store.dispatch("SAVE_TEMPORARY_FABIRC_SAVE", this.manufacture);
      else this.$store.dispatch("SAVE_PARSE_DATA", "production");
    },
  },
};
</script>
