<template>
  <v-dialog
    max-width="1200px"
    :value="dialogOpen"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-text class="pl-0 pr-0">
        <div
          class="grey lighten-2"
          style="
            height: 40px;
            width: 100%;
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.1);
            display: flex;
          "
        >
          <div class="ml-auto pr-2">
            <v-btn icon color="red" large @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <div class="pl-4 pr-4">
        <img
          v-if="$store.state.previewImage"
          :src="$store.state.previewImage"
          width="100%"
          height="800px"
          style="object-fit: cover"
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    dialogOpen() {
      return this.$store.state.isPreviewDialog ? true : false;
    },
    ...mapGetters({
      dialog: "getIsPrivewDialog",
    }),
  },
  methods: {
    close() {
      this.$store.commit("setIsPreviewDialog");
      window.URL.revokeObjectURL(this.$store.state.previewImage);
    },
  },
};
</script>
