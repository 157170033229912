export default [
  {
    text: "style No",
    width: "100px",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "color",
    width: "100px",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "출고량",
    width: "100px",
    align: "center",
    class: "tableHeaderBody",
  },

  {
    text: "잔여량",
    width: "100px",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "출고량(불합격)",
    width: "100px",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "잔여량(불합격)",
    width: "100px",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    value: "actions",
    width: "10px",
    sortable: false,
    class: "tableHeaderRigth",
  },
];
