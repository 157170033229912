//생산 페이지 로그 데이터 테이블 헤더
export default [
  {
    text: "날짜",
    value: "date_modify",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "작업",
    value: "log_detail",
    width: "10%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "내용",
    value: "detail",
    width: "20%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "담당자",
    value: "liaison",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "상태",
    value: "status",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "비고",
    value: "special_note",
    width: "20%",
    align: "center",
    class: "tableHeaderRigth",
  },
];
