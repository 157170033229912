<template>
  <div
    class="pt-2 pl-1 pr-1"
    style="display: grid; grid-template-columns: 4fr 6fr"
  >
    <div
      style="
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-template-rows: repeat(19, minmax(auto, 1.6rem));
      "
    >
      <div class="text-bold releaseDocGridBorderLeft">ORDER NO</div>
      <div class="releaseDocGridBorder">
        {{ docData.order }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">수신처</div>
      <div class="releaseDocGridBorder">
        {{ docData.place_manufacture ? docData.place_manufacture.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">발신인</div>
      <div class="releaseDocGridBorder">
        {{ docData.liaison ? docData.liaison.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">ITEM NO</div>
      <div class="releaseDocGridBorder">
        {{ docData.item ? docData.item.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">가 공</div>
      <div class="releaseDocGridBorder">
        {{ docData.post_processing ? docData.post_processing : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">폭</div>
      <div class="releaseDocGridBorder">
        {{ docData.width ? docData.width : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">수량</div>
      <div class="releaseDocGridBorder"></div>
      <div class="text-bold releaseDocGridBorderLeft">출고 납기</div>
      <div class="releaseDocGridBorder">
        {{ docData.date_payment ? dateFormat(docData.date_payment) : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">매입단가</div>
      <div
        :class="
          docData.currency_sign === '₩'
            ? 'releaseUnitPrice'
            : 'releaseUnitPriceExchange'
        "
        style="font-size: 11px"
      >
        <div style="text-align: right">
          {{
            (docData.currency_sign +
              (docData.unit_price ? docData.unit_price : ""))
              | makeComma
          }}
        </div>

        <div v-if="docData.currency_sign !== '₩'" style="text-align: right">
          {{ ("₩" + docData.eschamge) | makeComma }}
        </div>
      </div>
      <div class="text-bold releaseDocGridBorderLeft">COLOR 별 SAMPLE</div>
      <div class="releaseDocGridBorder">
        {{
          (docData.quantity_by_color
            ? docData.quantity_by_color + docData.unit_sign
            : "") | makeComma
        }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">검사소</div>
      <div class="releaseDocGridBorder">
        {{ docData.inspection ? docData.inspection.name : "미지정" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">Surcharge(단위)</div>
      <div class="releaseDocGridBorder d-flex justify-end">
        {{
          (docData.sucharge_price
            ? docData.currency_sign + docData.sucharge_price
            : "") | makeComma
        }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft">
        Surcharg <br />(제직, 건 당)
      </div>
      <div class="releaseDocGridBorder d-flex justify-end">
        {{
          (docData.surcharge_unit
            ? docData.currency_sign + docData.surcharge_unit.price
            : "") | makeComma
        }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="grid-row: span 3">
        STYLE NO
      </div>
      <div
        class="releaseDocGridBorder"
        style="
          grid-row: span 3;
          display: grid;
          overflow-y: overlay;
          overflow-x: hidden;
        "
      >
        <div
          v-for="item in docData.style_list"
          :key="item"
          class="mb-1"
          style="display: grid; width: 100%"
        >
          {{ item }}
        </div>
      </div>
      <div
        class="text-bold releaseDocGridBorderLeft"
        style="grid-column: span 2"
      >
        확인사항
      </div>
      <div
        class="text-bold releaseDocGridBorderLeft"
        style="
          grid-column: span 2;
          grid-row: span 2;
          border-bottom: thin solid rgba(0, 0, 0, 1);
        "
      >
        <v-textarea
          v-model="docData.confirmation"
          rows="4"
          no-resize
          clearable
          class="lefted-input"
          :outlined="false"
          clear-icon="mdi-close-circle"
          style="overflow-x: hidden; overflow-y: hidden"
          hide-details
          disabled
          :ref="`inputTab18`"
          tabindex="18"
        />
      </div>
    </div>

    <div
      style="
        display: grid;
        grid-template-rows: repeat(19, minmax(auto, 1.6rem));

        grid-template-columns: 2.2fr 2.2fr 2fr 1.7fr 1.7fr;
      "
    >
      <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
        COLOR
      </div>
      <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
        수량
      </div>
      <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
        수량<br />(샘플)
      </div>
      <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
        Surcharge
        <br />(단위당)
      </div>
      <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
        Surcharge<br />(Color당)
      </div>
      <div
        style="
          display: grid;
          overflow-y: overlay;
          grid-row: span 14;
          grid-column: span 5;
          overflow-y: overlay;
        "
      >
        <div
          v-for="color in docData.color_list"
          :key="color._id"
          style="
            display: grid;
            grid-template-columns: 2.2fr 2.2fr 2fr 1.7fr 1.7fr;
          "
        >
          <div class="BakRequestColorListBody">
            {{ color.name }}
          </div>
          <div class="BakRequestColorListBody">
            {{
              (color.quantity ? color.quantity + docData.unit_sign : "")
                | makeComma
            }}
          </div>
          <div class="BakRequestColorListBody">
            {{
              (color.quantity_sample
                ? color.quantity_sample + docData.unit_sign
                : "") | makeComma
            }}
          </div>
          <div class="BakRequestColorListBody">
            {{
              color.color_surcharge_unit
                ? docData.currency_sign + color.color_surcharge_unit
                : "" | makeComma
            }}
          </div>
          <div class="BakRequestColorListBody">
            {{
              color.color_surcharge_price
                ? color.color_surcharge_price.price
                  ? docData.currency_sign + color.color_surcharge_price.price
                  : ""
                : "" | makeComma
            }}
          </div>
        </div>
      </div>
      <div class="releaseGridBorder text-bold" style="grid-column: span 5">
        주 의 사 항
      </div>
      <div
        class="releaseGridBorderBotoomRight"
        style="grid-column: span 5; grid-row: span 2"
      >
        <v-textarea
          v-model="docData.precautions"
          rows="4"
          no-resize
          clearable
          :outlined="false"
          clear-icon="mdi-close-circle"
          style="overflow-x: hidden; overflow-y: hidden"
          hide-details
          disabled
          :ref="`inputTab18`"
          tabindex="18"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      docData: "getBakData",
    }),
  },
};
</script>
