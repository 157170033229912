<template>
  <div class="pt-1 pl-5" style="display: grid; grid-template-columns: 5fr 5fr">
    <div class="orderDocLeft">
      <div class="text-bold releaseGridBorderLeft">구매처</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.client ? docData.client.name : "" }}
      </div>
      <div class="text-bold releaseGridBorderLeft">복종</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.ctype ? docData.ctype.name : "" }}
      </div>
      <div class="text-bold releaseGridBorderLeft">성별</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.order ? docData.gender : "" }}
      </div>
      <div class="text-bold releaseGridBorderLeft">ITEM NO(자사)</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.order ? docData.item.name : "" }}
      </div>
      <div class="text-bold releaseGridBorderLeft">ITEM NO(생산처)</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.order ? docData.item.production_item : "" }}
      </div>
      <div class="text-bold releaseGridBorderLeft">오더 유형</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.order ? docData.order_kind.label : "" }}
      </div>
      <div class="text-bold releaseGridBorderLeft">매입 구분</div>
      <div class="text-bold releaseGridBorder">
        {{ docData.order ? docData.purchase_classification : "" }}
      </div>

      <div class="text-bold releaseGridBorderLeft">통화단위</div>
      <div class="text-bold releaseGridBorder">
        {{
          docData.order
            ? docData.eschamge_sign + docData.sales_unit_price
            : "" | makeComma
        }}
      </div>
      <div
        class="text-bold releaseGridBorderLeft"
        style="border-bottom: thin solid rgba(0, 0, 0, 1)"
      >
        담당자
      </div>
      <div
        class="text-bold releaseGridBorder"
        style="border-bottom: thin solid rgba(0, 0, 0, 1)"
      >
        {{ docData.liaison ? docData.liaison.name : "" }}
      </div>
    </div>
    <div
      style="
        display: grid;
        grid-template-rows: repeat(16, minmax(auto, 2.2rem));
      "
    >
      <div class="text-bold orderDocStyle">STYLE LIST</div>
      <div style="display: grid; grid-row: span 7; overflow-y: overlay">
        <div v-for="(style, i) in docData.style_list" :key="i">
          <div class="orderDocList" style="height: 2.2rem; overflow: auto">
            {{ style }}
          </div>
        </div>
      </div>
      <div class="text-bold orderDocList">COLOR LIST</div>
      <div style="display: grid; grid-row: span 7; overflow-y: overlay">
        <div
          v-for="(color, i) in docData.color_list"
          :key="i"
          style="display: grid; grid-template-columns: 4fr 6fr"
        >
          <div
            class="orderDocList"
            style="
              border-left: thin solid rgba(0, 0, 0, 1);
              height: 2.2rem;
              overflow: auto;
            "
          >
            {{ color.name ? color.name : "" }}
          </div>
          <div class="orderDocList">
            {{
              color.quantity
                ? color.quantity + docData.unit_sign
                : "" | makeComma
            }}
          </div>
        </div>
      </div>
      <div
        style="
          border: thin solid rgba(0, 0, 0, 1);
          height: 2.2rem;
          font-size: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        TOTAL :
        {{
          (docData.colorTota ? docData.colorTota + docData.unit_sign : 0)
            | makeComma
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      docData: "getDoctData",
    }),
  },
};
</script>
