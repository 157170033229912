<template>
  <div class="pt-1 pl-5" style="display: grid; grid-template-columns: 4fr 6fr">
    <div
      style="
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-template-rows: repeat(14, minmax(auto, 2rem));
      "
    >
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        ORDER NO
      </div>
      <div class="releaseDocGridBorder">{{ docData.order }}</div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        수신처
      </div>
      <div class="releaseDocGridBorder">
        {{ docData.place_forward ? docData.place_forward.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        입고처
      </div>
      <div class="releaseDocGridBorder">
        {{ docData.place_receive ? docData.place_receive.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        발신인
      </div>
      <div class="releaseDocGridBorder">
        {{ docData.liaison ? docData.liaison.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        출고일
      </div>
      <div class="releaseDocGridBorder">
        {{ docData.date_delivery ? dateFormat(docData.date_delivery) : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        ITEM NO
      </div>
      <div class="releaseDocGridBorder">
        {{ docData.item ? docData.item.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        매출처
      </div>
      <div class="releaseDocGridBorder">
        {{ docData.place_sales ? docData.place_sales.name : "" }}
      </div>
      <div class="text-bold releaseDocGridBorderLeft" style="height: 2rem">
        {{
          docData.currency_sign === "₩" || !docData.currency_sign
            ? "매출단가"
            : "매출단가/환율"
        }}
      </div>
      <div
        :class="
          docData.currency_sign === '₩' || !docData.currency_sign
            ? 'releaseUnitPrice'
            : 'releaseUnitPriceExchange'
        "
      >
        <div
          v-if="docData.sales_unit_price"
          style="
            display: flex;
            justify-content: right;
            align-items: center;
            font-size: 11px;
          "
        >
          {{
            ((docData.currency_sign ? docData.currency_sign : "₩") +
              (docData.sales_unit_price ? docData.sales_unit_price : ""))
              | makeComma
          }}
        </div>

        <div
          v-if="
            docData.sales_unit_price &&
            docData.currency_sign !== '₩' &&
            docData.currency_sign
          "
          style="
            display: flex;
            justify-content: right;
            align-items: center;
            font-size: 11px;
          "
        >
          {{ ("₩" + docData.sales.eschamge) | makeComma }}
        </div>
      </div>
      <div
        class="text-bold releaseDocGridBorderLeft"
        style="grid-column: span 2; height: 2rem"
      >
        주 의 사 항
      </div>
      <div
        class="releaseGridBorderBotoom"
        style="grid-column: span 2; grid-row: span 5"
      >
        <v-textarea
          rows="5"
          no-resize
          clearable
          :outlined="false"
          clear-icon="mdi-close-circle"
          hide-details
          v-model="docData.precautions"
          @keydown.native="tabMove($event, 8)"
          :ref="`inputTab8`"
          :tabindex="8"
          disabled
          style="overflow-x: hidden; overflow-y: hidden"
        />
      </div>
    </div>
    <div
      style="
        display: grid;
        grid-template-rows: repeat(14, minmax(auto, 2rem));
        grid-template-columns: 3fr 3fr 4fr;
      "
    >
      <div class="text-bold releaseDocColorListHeader" style="height: 1.95rem">
        STYLE
      </div>
      <div class="text-bold releaseDocColorListHeader" style="height: 1.95rem">
        COLOR
      </div>
      <div class="text-bold releaseDocColorListHeader" style="height: 1.95rem">
        수 량
      </div>
      <div
        style="
          display: grid;
          grid-column: span 3;
          grid-row: span 12;
          overflow-y: overlay;
        "
      >
        <div
          v-for="color in docData.requestColor"
          :key="color._id"
          style="display: grid; grid-template-columns: 3fr 3fr 4fr"
        >
          <div class="releaseDocColorListBody" style="height: 100%">
            <v-text-field
              dense
              :value="color.style"
              hide-details
              flat
              solo
              type="string"
              height="1.7rem"
              class=""
              style="font-size: 11px"
              readonly
            ></v-text-field>
          </div>
          <div class="releaseDocColorListBody" style="height: 100%">
            <v-text-field
              dense
              :value="color.name"
              hide-details
              flat
              solo
              type="string"
              height="1.7rem"
              class=""
              style="font-size: 11px"
              readonly
            ></v-text-field>
          </div>
          <div class="releaseDocColorListBody" style="height: 100%">
            <v-text-field
              dense
              :value="
                color.name
                  ? (color.success_quantity
                      ? color.success_quantity
                      : color.quantity
                      ? color.quantity
                      : 0) + docData.unit_sign
                  : '' | makeComma
              "
              hide-details
              flat
              solo
              type="string"
              height="1.7rem"
              class="text-center"
              style="font-size: 11px"
              readonly
            ></v-text-field>
          </div>
        </div>
      </div>
      <div
        class="releaseColorListTot"
        style="grid-column: span 2; height: 2rem"
      >
        TOTAL
      </div>
      <div class="releaseColorListTot" style="height: 1.95rem">
        {{ (totalQuantity + docData.unit_sign) | makeComma }}
      </div>
    </div>

    <div
      class="pt-2"
      style="
        display: grid;
        grid-area: 3/1/3/3;
        grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
        grid-template-rows: repeat(2, minmax(auto, 2rem));
      "
    >
      <div class="releaseCaculateTop" style="font-size: 11px">매입수량</div>
      <div class="releaseCaculateTop" style="font-size: 11px">매입단가</div>
      <div class="releaseCaculateTop" style="font-size: 11px">금액</div>
      <div class="releaseCaculateTop" style="font-size: 11px">
        surcharge
        <br />
        (염색, 건 당)
      </div>
      <div class="releaseCaculateTop" style="font-size: 11px">
        surcharge
        <br />
        (제직, 건당)
      </div>
      <div
        class="releaseCaculateTop"
        style="border-right: thin solid rgba(0, 0, 0, 1); font-size: 11px"
      >
        금액 합
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        {{
          docData.purchase.quantity
            ? docData.purchase.quantity + docData.purchase.unit_sign
            : "" | makeComma
        }}
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        {{
          docData.purchase.unit_price
            ? docData.purchase.currency_sign + docData.purchase.unit_price
            : "" | makeComma
        }}
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        {{
          docData.purchase.quantity
            ? docData.purchase.currency_sign +
              docData.purchase.quantity * docData.purchase.unit_price
            : "" | makeComma
        }}
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        {{
          docData.purchase.surcharge_price
            ? docData.purchase.currency_sign + docData.purchase.surcharge_price
            : "" | makeComma
        }}
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        {{
          docData.purchase.color_surcharge_price
            ? docData.purchase.currency_sign +
              docData.purchase.color_surcharge_price
            : "" | makeComma
        }}
      </div>
      <div
        class="releaseCaculateBottom"
        style="border-right: thin solid rgba(0, 0, 0, 1); font-size: 11px"
      >
        {{
          docData.purchase.total_amount
            ? docData.purchase.currency_sign + docData.purchase.total_amount
            : "" | makeComma
        }}
        <!-- {{ purchaseExchange | makeComma }} -->
      </div>
    </div>

    <div
      class="pt-2"
      style="
        display: grid;
        grid-area: 4/1/4/3;
        grid-template-columns: 3fr 3.5fr 3.5fr;
        grid-template-rows: repeat(2, minmax(auto, 2rem));
      "
    >
      <div class="releaseCaculateTop" style="font-size: 11px">매출수량</div>
      <div class="releaseCaculateTop" style="font-size: 11px">매출단가</div>
      <div
        class="releaseCaculateTop"
        style="border-right: thin solid rgba(0, 0, 0, 1); font-size: 11px"
      >
        매출금액
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        <div style="display: flex; align-items: center">
          {{
            docData.sales.quantity
              ? docData.sales.quantity + docData.unit_sign
              : "" | makeComma
          }}
        </div>
      </div>
      <div class="releaseCaculateBottom" style="font-size: 11px">
        {{
          docData.sales_unit_price
            ? (docData.currency_sign ? docData.currency_sign : "₩") +
              docData.sales_unit_price
            : "" | makeComma
        }}
      </div>
      <div
        class="releaseCaculateBottom"
        style="border-right: thin solid rgba(0, 0, 0, 1); font-size: 11px"
      >
        {{
          docData.sales.total_amount
            ? (docData.currency_sign ? docData.currency_sign : "₩") +
              docData.sales.total_amount
            : "" | makeComma
        }}

        {{ salesExchange | makeComma }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      docData: "getDoctData",
    }),
    salesExchange() {
      const eschamge =
        this.docData.currency_sign !== "₩" ? this.docData.sales.eschamge : 0;
      const total_amount = this.docData.sales.total_amount
        ? this.docData.sales.total_amount
        : 0;
      const eschamge_amount = eschamge * total_amount;

      return eschamge_amount ? `  (₩${eschamge_amount})` : "";
    },

    totalQuantity() {
      return this.docData.requestColor.length
        ? this.docData.requestColor.reduce(
            (a, c) =>
              a +
              +(c.name
                ? c.success_quantity
                  ? +c.success_quantity
                  : c.quantity
                  ? +c.quantity
                  : 0
                : 0),
            0,
          )
        : 0;
    },
  },
};
</script>

<style></style>
