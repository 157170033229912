import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      doc: "getRequestDoc",
      loginUser: "getLoginUser",
      colorList: "getProductionColor",
      colorTotal: "getProductionColorTot",
    }),
    docTitle() {
      return this.doc.kind === 7
        ? "가공의뢰서"
        : this.doc.kind === 11
        ? "외관 검사서"
        : this.doc.kind === 99
        ? ""
        : "출고의뢰서";
    },
    isNewDocBtn() {
      return this.doc.kind === 99;
    },

    isDeleteBtn() {
      let btn;
      if (this.doc.kind === 7)
        btn =
          this.doc.status === "70"
            ? false
            : this.loginUser.grade >= 2
            ? !(this.doc.id && this.doc.status === "40" && this.doc.isModify)
            : // : !(this.doc.status === "20" || this.doc.status === "30") &&
              !(this.doc.status === "30") &&
              !(this.doc.id && this.doc.status === "40" && this.doc.isModify);
      else if (this.doc.kind === 11)
        btn =
          this.doc.status === "99"
            ? true
            : !(!this.doc.isModify && this.doc.id);
      else {
        btn =
          this.loginUser.grade >= 2
            ? !(this.doc.id && this.doc.status === "40" && this.doc.isModify)
            : !(
                this.doc.id &&
                (this.doc.status === "40" || this.doc.status === "30") &&
                this.doc.isModify
              );
      }

      return btn;
    },

    isSaveBtn() {
      let btn;
      if (this.doc.kind === 7) {
        btn = !(
          !this.doc.id ||
          ((this.doc.status === "40" ||
            this.doc.status === "30" ||
            this.doc.status === "70") &&
            this.doc.isModify)
        );
      } else if (this.doc.kind === 11)
        btn = this.doc.status === "99" ? true : this.doc.isModify;
      else
        btn = !(
          (!this.doc.id ||
            this.doc.status === "40" ||
            this.doc.status === "30" ||
            this.doc.status === "70") &&
          this.doc.isModify
        );

      return btn;
    },

    saveBtnLabel() {
      return this.doc.status === "40" ? "수 정" : "저 장";
    },

    userBtnTitle() {
      return this.doc.kind === 11
        ? this.doc.id
          ? "수 정"
          : "저 장"
        : this.doc.stockBtn && this.doc.requestDoc.kind === 0
        ? !this.doc._id
          ? "재고 등록"
          : "재고 수정"
        : !this.doc.id ||
          this.doc.status === "30" ||
          this.doc.status === "40" ||
          this.doc.status === "70"
        ? "승인 요청"
        : "승인 요청";
    },

    bakDialog() {
      return !(this.doc.history && this.doc.history.length > 0);
    },
  },
  methods: {
    async initDoc() {
      await this.$store.dispatch(
        "SET_PRODUCTION_ORDER",
        this.$store.state.productionOrderForm,
      );

      this.doc.kind === 11
        ? this.$store.dispatch("APPEARANCE_CLEAR")
        : this.doc.kind === 7
        ? this.$store.dispatch("MANUFACTURE_CLEAR", "clear")
        : this.initRelease();

      this.$store.dispatch("EDIT_FORM_CLOSE");
    },

    initRelease() {
      this.$store.dispatch("RELEASEFORM_CLEAR");
      this.$store.dispatch("SET_DOC_LIST", this.doc.salesBtn);
    },

    deleteDoc() {
      this.$store.dispatch("EDIT_FORM_CLOSE");
      const param = {
        dialog: true,
        status: this.doc.status,
        kind:
          this.doc.kind === 7
            ? "manufacture"
            : this.doc.kind === 11
            ? "appearance"
            : "release",
        id: this.doc.id,
        isOrder: !!(
          this.doc.kind === 7 &&
          this.doc.isOrder &&
          this.loginUser.grade >= 2
        ),
      };
      if (param.kind === "release") this.colorCalculation();
      this.$store.commit("setCancelDialog", param);
    },

    saveDoc() {
      this.$store.dispatch("EDIT_FORM_CLOSE");
      if (this.doc.kind === 7) this.manufactureNotEntered();
      else if (this.doc.kind === 11) this.appearanceSave();
      else if (this.doc.kind !== 7 && this.doc.kind !== 11)
        this.releaseNOInputCheck();
    },
    // 가고의뢰서 저장
    manufactureNotEntered() {
      const noInputNames = [];
      if (!this.doc.place_manufacture) {
        this.snackbarOn("수신처를 입력해주세요");
        return;
      }
      if (!this.doc.item) {
        this.snackbarOn("ITEM 을 입력해주세요");
        return;
      }
      if (!this.doc.date_payment) {
        this.snackbarOn("출고 납기 를 입력해주세요");
        return;
      }
      if (!this.doc.unit_price) {
        this.snackbarOn("단가 를 입력해 주세요");
        return;
      }
      this.colorList.forEach((x, i) => {
        if (
          (x.quantity !== undefined && x.quantity !== "") ||
          (x.quantity_sample !== undefined && x.quantity_sample !== "")
        )
          !x.name ? noInputNames.push(i + 1) : "";
      });
      if (noInputNames.length) {
        this.snackbarOn(
          `${noInputNames.join(",")} 번째 열 컬러를 입력해 주세요`,
        );
        return;
      }
      if (this.doc.total_quantity_sum === 0) {
        this.snackbarOn("컬러 수량을 입력해주세요");
        return;
      }

      this.doc.refer = false;
      this.$store.commit("setProductionConfirm");
    },

    releaseNOInputCheck() {
      if (!this.doc.date_delivery) {
        this.snackbarOn("출고일을 입력해 주세요");
        return;
      }
      if (!this.doc.requestDoc) {
        this.snackbarOn("가공의뢰서 또는 외관 검사서를 입력해주세요");
        return;
      }
      if (!this.doc.place_forward) {
        this.snackbarOn("수신처를 입력해주세요");
        return;
      }

      if (this.doc.appenBtn && !this.doc.place_receive) {
        this.snackbarOn("입고하실 검사소를 입력해 주세요");
        return;
      }

      if (!this.doc.item) {
        this.snackbarOn("아이템을 입력해주세요");
        return;
      }
      if (this.doc.requestDoc.kind === 7 && this.colorTotal === 0) {
        this.snackbarOn("수량을 입력해 주세요");
        return;
      }
      if (
        this.doc.requestDoc.kind === 11 &&
        this.colorTotal.sucessTot === 0 &&
        this.colorTotal.failTot === 0
      ) {
        this.snackbarOn("수량을 입력해 주세요");
        return;
      }
      if (this.doc.requestDoc.kind === 12 && this.colorTotal.quantitTot === 0) {
        this.snackbarOn("수량을 입력해 주세요");
        return;
      }
      if (
        this.doc.status !== "50" ||
        (!this.loginUser.grade >= 2 && this.doc.status === "20") ||
        (!this.loginUser.grade >= 2 && this.doc.status === "30")
      )
        this.colorCalculation();

      this.setReleaseKind();

      if (this.doc.preBtn) this.doc.refer = false;
      this.$store.commit("setProductionConfirm");
    },

    colorCalculation() {
      const saveColor = {
        colorList: this.makeColorList(),
        purchase: this.doc.purBtn ? this.$store.state.purchasCacul : {},
        sales: this.doc.salesBtn ? this.$store.state.salesCacul : {},
        total_quantity: this.doc.fabricBtn
          ? this.doc.color_list[0].quantity
          : this.doc.requestDoc.kind === 7
          ? this.colorTotal
          : this.colorTotal.sucessTot,
      };

      if (this.doc.purBtn) saveColor.purchase.isNotVat = this.doc.isPurNotVat;
      if (this.doc.salesBtn) saveColor.sales.isNotVat = this.doc.isSalesNotVat;

      this.$store.commit("setSaveReleaseColor", saveColor);
    },

    makeColorList() {
      const colorList = [];
      this.colorList.forEach((x) => {
        if (x.name && (x.success_quantity || x.quantity)) {
          const color = {
            name: x.name,
            style: x.style,
            quantity: x.success_quantity ? x.success_quantity : x.quantity,
            indexColorName: x.indexColorName,
          };
          colorList.push(color);
        }
      });

      return colorList;
    },

    setReleaseKind() {
      if (this.doc.purBtn) this.doc.kind = 8;

      if (
        this.doc.salesBtn &&
        (this.doc.requestDoc.kind === 12 || this.doc.requestDoc.kind === 17)
      )
        this.doc.kind = 9;

      if (this.doc.appenBtn) this.doc.kind = 10;

      if (
        this.doc.purBtn &&
        this.doc.salesBtn &&
        this.doc.requestDoc.kind !== 12 &&
        this.doc.requestDoc.kind !== 17
      )
        this.doc.kind = 15;

      if (this.doc.purBtn && this.doc.preBtn) this.doc.kind = 12;

      if (!this.doc.appenBtn && !this.doc.purBtn && !this.doc.salesBtn)
        this.doc.kind = 16;

      if (this.doc.stockBtn) this.doc.kind = 17;

      if (this.doc.fabricBtn) this.doc.kind = 19;
    },

    //외관 검사서 저장
    appearanceSave() {
      if (!this.doc.release) {
        this.snackbarOn("검사 의뢰서를 선택해주세요");
        return;
      }
      if (
        this.doc.color_list.reduce(
          (r, c) => r + (+c.sucess_quantity + +c.fail_quantity),
          0,
        ) === 0
      ) {
        this.snackbarOn("검사 수량을 입력해주세요");
        return;
      }
      this.saveAppearance();
    },

    saveAppearance() {
      this.$store.dispatch("PARSE_PRODUCTION_DATA");
    },

    ///////////////////////////// 생지 수량 계산 dialog open//////////////////////////////
    fabricCaculateDialogOpen() {
      this.$store.commit("setFabricCaculateDialog");
    },

    ///////////////////////////////////////////////////////////////////////

    openBakDialog() {
      this.$store.dispatch("EDIT_FORM_CLOSE");
      this.$store.commit("setBakDocListDialog");
    },

    //snacbarOn
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },
  },
};
