import VueContext from "vue-context";
import "vue-context/src/sass/vue-context.scss";

export default {
  data() {
    return {
      includeModifyPage: [
        "order",
        "claim",
        "endClaim",
        "production",
        "purchasecalculate",
        "purchasedeadline",
        "salescalculate",
        "salesdeadline",
        "stock",
      ],
    };
  },
  components: {
    VueContext,
  },

  methods: {
    requestFormOpen(item) {
      const historyList = this.$store.state.apprvoalHistory;
      this.$store.dispatch("SET_REQUEST_FORM_DATA", {
        approvalId: item.approvalId,
        name: item.name,
        id: item.id,
        order: item.order,
      });
      this.$store.commit("setRequestApprovalList", historyList);
      this.$store.commit("setRequestFormDialog");
    },
  },
};
