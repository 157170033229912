<template>
  <v-dialog v-model="isDoalog" width="900px" persistent>
    <!-- @keydown.esc="dialog_release_history = false" -->
    <v-card>
      <v-card-title class="headline grey lighten-2"> 버전 기록 </v-card-title>

      <div style="height: 500px; border-bottom: thin solid rgba(0, 0, 0, 1)">
        <div
          style="
            float: left;
            width: 30%;
            padding-top: 1%;
            padding-left: 4px;
            padding-right: 4px;
            height: 100%;
            border-right: thin solid rgba(0, 0, 0, 1);
          "
        >
          <div
            style="
              display: grid;
              grid-template-rows: repeat(12, minmax(auto, 40px));
              grid-template-columns: 3fr 7fr;
            "
          >
            <div
              class="releaseColorListHeader"
              style="
                border-left: thin solid rgba(0, 0, 0, 1);
                border-bottom: thin solid rgba(0, 0, 0, 1);
              "
            >
              날 짜
            </div>
            <div
              class="releaseColorListHeader"
              style="border-bottom: thin solid rgba(0, 0, 0, 1)"
            >
              내 역
            </div>

            <div
              style="
                display: grid;

                grid-row: span 10;
                grid-column: span 2;
                max-height: 45vh;
              "
            >
              <div
                v-for="item in docList"
                :key="item._id"
                style="display: grid; grid-template-columns: 3fr 7fr"
                @click="openBakDoc(item)"
              >
                <div
                  class="bakDocList"
                  style="border-left: thin solid rgba(0, 0, 0, 1); height: 40px"
                >
                  {{ item.date ? dateFormat(item.date) : "" }}
                </div>
                <div class="bakDocList" style="height: 40px">
                  {{ item.log ? item.log : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="float: right; width: 70%">
          <template v-if="bakData.id">
            <BakManufactureForm v-if="bakData.kind === 7" />
            <BakReleaseForm v-else />
          </template>
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> 닫기 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import BakManufactureForm from "@/components/pages/production/manufacture/BakManufactureForm.vue";
import BakReleaseForm from "@/components/pages/production/release/BakReleaseForm.vue";
import { getFindDocData } from "@/api/approval";

export default {
  components: {
    BakManufactureForm,
    BakReleaseForm,
  },
  data() {
    return {
      headersHistory: [
        {
          text: "날짜",
          width: "40%",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "내용",
          width: "70%",
          align: "center",
          class: "tableHeaderRigth",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      form: "getProductionForm",
      itemList: "getItemList",
      clientList: "getUseClientList",
      isDoalog: "getBakDocListDialog",
      doc: "getRequestDoc",
      bakData: "getBakData",
    }),

    docList() {
      const list = this.doc.id
        ? this.doc.history
            .map((x, i) => {
              return {
                id: x.id ? x.id : "",
                date: x.date,
                log: i + 1,
                saveType: x.saveType ? x.saveType : 1,
              };
            })
            .reverse()
        : [];

      if (list.length < 10) {
        let num = 10 - list.length;
        for (let i = 0; i < num; i++) {
          list.push({});
        }
      }

      return list;
    },
  },

  methods: {
    //전달 받은 approvalId 값으로 approval 데이터를 검색한뒤 반환 하는 함수
    async requestDocDataFind(approvalId) {
      try {
        const result = await getFindDocData({
          approvalId,
          company: this.$store.state.auth_company,
          docType: this.doc.kind === 7 ? "manufacture" : "release",
        });

        if (result.status !== 200) throw `ERROR ${result.status}`;

        return this.doc.kind === 7
          ? result.data.manufactureData
          : result.data.releaseData;
      } catch (e) {
        console.log(e);
      }
    },

    //전달받은 문서 id 로 오더에서 문서를 검색한뒤 반환 하는 함수
    findOrderDoc(id) {
      const list = JSON.parse(
        JSON.stringify([...this.form.release, ...this.form.manufacture]),
      );
      return list.find((doc) => doc.id === id);
    },

    async openBakDoc(data) {
      const docData =
        data.saveType === 1
          ? this.findOrderDoc(data.id)
          : await this.requestDocDataFind(data.id);

      this.$store.commit(
        "setBakData",
        this.doc.kind === 7
          ? this.openBakManufactureDialog(docData)
          : this.openBakReleaseDialog(docData),
      );
    },

    openBakReleaseDialog(doc) {
      const bakData = doc;

      bakData.order = this.form.order;
      bakData.liaison = this.$store.state.userList.find(
        (x) => x._id === bakData.liaison,
      );
      bakData.requestColor = bakData.color_list ? bakData.color_list : [];

      bakData.place_forward = this.clientList.find(
        (x) => x._id === bakData.place_forward,
      );

      bakData.place_receive = this.clientList.find(
        (x) => x._id === bakData.place_receive,
      );
      bakData.place_sales = this.clientList.find(
        (x) => x._id === bakData.place_sales,
      );
      bakData.item = this.itemList.find((x) => x._id === bakData.item);

      if (bakData.kind === 9 || bakData.kind === 15) {
        bakData.surcharge_unit = bakData.sales.surcharge_unit
          ? this.usingComma(bakData.sales.surcharge_unit)
          : "";

        bakData.color_surcharge_unit = bakData.sales.color_surcharge_unit
          ? this.usingComma(bakData.sales.color_surcharge_unit)
          : "";
      }

      if (bakData.requestColor.length < 12) {
        const num = 12 - bakData.requestColor.length;
        for (let i = 0; i < num; i++) {
          bakData.requestColor.push({});
        }
      }

      return bakData;
    },

    openBakManufactureDialog(doc) {
      const bakData = doc;

      bakData.order = this.form.order;
      bakData.date_payment = this.dateFormat(bakData.date_payment);
      bakData.date_register = this.dateFormat(bakData.date_register);
      bakData.place_manufacture = this.clientList.find(
        (x) => x._id === bakData.place_manufacture,
      )
        ? this.clientList.find((x) => x._id === bakData.place_manufacture).name
        : "";
      bakData.item = this.itemList.find((x) => x._id === bakData.item);
      bakData.inspection
        ? (bakData.inspection = this.clientList.find(
            (x) => x._id === bakData.inspection,
          ))
        : "";

      bakData.liaison = this.doc.liaison.name;

      if (bakData.color_list.length < 14) {
        const emptyDataLength = 14 - bakData.color_list.length;
        for (let i = 0; i < emptyDataLength; i++) bakData.color_list.push({});
      }

      bakData.total_quantity_sum = bakData.color_list
        .filter((x) => x.quantity || x.quantity_sample)
        .reduce(
          (r, c) =>
            r +
            (c.quantity_sample
              ? +c.quantity
                ? +c.quantity + +c.quantity_sample
                : +c.quantity_sample
              : +c.quantity),
          0,
        );
      return bakData;
    },

    close() {
      this.$store.commit("setBakDocListDialog");
      this.$store.commit("setBakData");
    },
  },
};
</script>
