<template>
  <v-dialog
    scrollable
    v-model="$store.state.form_reset_check"
    persistent
    no-click-animation
    width="410px"
    @keydown.esc="$store.commit('setResetCheck')"
    @keydown.enter="dialogClose()"
  >
    <v-card>
      <v-card-title class="text-h5"> 현재 창을 닫으시겠습니까? </v-card-title>
      <v-card-text style>현재 작성중인 문서를 닫으시겠습니까?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialogClose()" color="primary" text> 예 </v-btn>
        <v-btn @click="$store.commit('setResetCheck')" color="primary" text>
          아니오
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    dialogClose() {
      const data = this.$store.state.manufactureForm.dialog
        ? this.$store.state.manufactureForm
        : this.$store.state.releaseForm.dialog
        ? this.$store.state.releaseForm
        : this.$store.state.appearanceForm.dialog
        ? this.$store.state.appearanceForm
        : "";
      data.color_List = this.$store.state.productionColor;
      data.dialog = false;

      this.$store.commit("setProductionColor");
      this.$store.commit("setResetCheck");
      this.$store.commit("setDocData");
      this.$store.commit("setDocData");
      this.$store.commit("setSelectDocData");

      if (this.$store.state.isDocDataBtn) this.$store.commit("setIsDocDataBtn");
    },
  },
};
</script>

<style></style>
