<template>
  <v-dialog
    scrollable
    v-model="this.$store.state.approvalDialog"
    width="1000"
    persistent
    no-click-animation
    @keydown.esc="$store.commit('setApprovalDialog', false)"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        승인 요청이 있습니다.
      </v-card-title>
      <v-card-text height="1000px">
        <v-data-table
          :headers="approvalHeader"
          :items="this.$store.state.approvalList"
          item-key="_id"
          disable-sort
          @click:row="setOrderFromByApproval"
        >
          <template v-slot:[`item.status`]="{ item }">
            {{ $store.state.order_status[item.status] }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="$store.commit('setApprovalDialog', false)"
          color="primary"
          text
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import approvalHeader from "@/assets/table/approval/production";

export default {
  data() {
    return {
      approvalHeader,
    };
  },
  computed: {
    ...mapGetters({
      userList: "getUserList",
      form: "getForm",
      approval: "getApproval",
    }),
    approvalList() {
      return !this.$route.params.kind
        ? this.$store.state.approvalList
        : this.$route.params.kind === "release" ||
          this.$route.params.kind === "sales"
        ? this.$store.state.approvalList.filter((x) => x.kind === "release")
        : this.$store.state.approvalList.filter(
            (x) => x.kind === "manufacture",
          );
    },
  },
  methods: {
    async setOrderFromByApproval(data) {
      await this.$store.dispatch("postCompany", data.id.order);
      const progressIndex = this.form.progress.findIndex(
        (x) =>
          (data.kind === "가공의뢰서" ? x.manufacture : x.release) ===
          data.id._id,
      );
      const progress = this.form.progress[progressIndex];
      progress.index = progressIndex;

      this.$store.dispatch("PRODUCTION_OPENDIALOG", progress);
    },
  },
};
</script>

<style></style>
